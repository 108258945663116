.age-gate {
	box-sizing: border-box;
	padding: var(--space-30);
	text-align: center;
	text-transform: uppercase;
	width: 100%;
}

@media (min-width: 768px) {
	.age-gate {
		min-width: 500px;
	}
}

@media (min-width: 1024px) {
	.age-gate {
		min-width: 575px;
	}
}

@media (min-width: 1280px) {
	.age-gate {
		padding: var(--space-40);
	}
}

.age-gate__overline:not(:last-child) {
	margin-bottom: var(--space-30);
}

.age-gate__title {
	display: inline-block;
	margin-top: 0;
	max-width: 250px;
}

@media (min-width: 1280px) {
	.age-gate__title {
		max-width: 350px;
	}
}

.age-gate__title:not(:last-child) {
	margin-bottom: var(--space-30) !important;
}

@media (min-width: 1280px) {
	.age-gate__title:not(:last-child) {
		margin-bottom: var(--space-40) !important;
	}
}

.age-gate__option-group {
	display: flex;
	flex-wrap: wrap;
	margin-right: auto;
	margin-left: auto;
	justify-content: center;
	align-items: center;
	max-width: 440px;
}

.age-gate__option-group:not(:last-child) {
	margin-bottom: var(--space-25);
}

@media (min-width: 1280px) {
	.age-gate__option-group:not(:last-child) {
		margin-bottom: var(--space-35);
	}
}

.age-gate__option {
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: var(--space-15);
	margin-bottom: var(--space-15);
	max-width: 100%;
}

@media (min-width: 568px) {
	.age-gate__option {
		margin-right: var(--space-30);
	}
}

.age-gate__option:last-child {
	margin-right: 0;
}

.age-gate__option__label,
.age-gate__option__link {
	color: currentColor;
	cursor: pointer;
	display: inline-block;
	line-height: var(--space-20);
	margin-left: calc(0.9375rem + var(--space-10)); /* checkbox width + spacing */
	position: relative;
}

@media (min-width: 1280px) {
	.age-gate__option__label,
	.age-gate__option__link {
		margin-left: calc(1.25rem + var(--space-20)); /* checkbox width + spacing */
	}
}

.age-gate__option__label::after,
.age-gate__option__label::before,
.age-gate__option__link::after,
.age-gate__option__link::before {
	border: 2px solid var(--color-foreground);
	border-radius: 100%;
	box-sizing: border-box;
	content: "";
	position: absolute;
	top: 0.0625rem;
	left: calc((0.9375rem + var(--space-10)) * -1); /* checkbox width + spacing */
	width: 0.9375rem;
	height: 0.9375rem;
}

@media (min-width: 1280px) {
	.age-gate__option__label::after,
	.age-gate__option__label::before,
	.age-gate__option__link::after,
	.age-gate__option__link::before {
		top: -0.0625rem;
		left: calc((1.25rem + var(--space-20)) * -1); /* checkbox width + spacing */
		width: 1.25rem;
		height: 1.25rem;
	}
}

.age-gate__option__label::before,
.age-gate__option__link::before {
	background-color: var(--color-background);
}

.age-gate__option__label::after,
.age-gate__option__link::after {
	background-color: var(--color-foreground);
	box-shadow: inset 0 0 0 0.125rem var(--color-background);
	display: none;
}

@media (min-width: 1280px) {
	.age-gate__option__label::after,
	.age-gate__option__link::after {
		box-shadow: inset 0 0 0 0.1875rem var(--color-background);
	}
}

.age-gate__option__label {
	cursor: pointer;
}

.age-gate__option__link {
	text-decoration: none;
}

.age-gate__option__link:focus {
	outline: 0;
}

.age-gate__option__link:focus::before {
	box-shadow: 0 0 4px 2px var(--color-blue-light);
}

.age-gate__option__link:focus::after,
.age-gate__option__link:active::after {
	display: block;
}

/* STATES */
#age-yes:checked ~ * .age-gate__option__label[for="age-yes"]::after,
#age-no:checked ~ * .age-gate__option__label[for="age-no"]::after {
	display: block;
}

#age-yes:focus ~ * .age-gate__option__label[for="age-yes"]::before,
#age-no:focus ~ * .age-gate__option__label[for="age-no"]::before {
	box-shadow: 0 0 4px 2px var(--color-blue-light);
}

#age-yes:not(:checked) ~ .age-gate__title--region,
#age-yes:not(:checked) ~ * .age-gate__option__link {
	opacity: 0.5; /* TODO : there is no design for this state, just did something */
}

#age-yes:not(:checked) ~ * .age-gate__option__link {
	cursor: not-allowed;
}

/* We only show a close button for visitors that have previously passed the age gate */
/* stylelint-disable-next-line @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
#age-gate-modal:not([passed]) #age-gate-modal-close {
	display: none;
}

/* Hide other dialogs when the age gate is open */
#age-gate-modal:not([data-state="closed"]) ~ .modal-dialog {
	display: none;
}
