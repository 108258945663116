.beer-teaser {
	overflow: hidden;
	width: 100%;
}

.beer-teaser__link {
	box-sizing: border-box;
	color: currentColor;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: var(--space-15) var(--space-20);
	justify-content: space-between;
	align-items: center;
	position: relative;
	text-align: center;
	text-decoration: none;
	width: 100%;
	min-height: 200px;
}

.beer-teaser__title,
.beer-teaser__tagline {
	position: relative;
	z-index: 1;
}

.beer-teaser__title {
	display: inline-block;
	flex-basis: 125px; /* IE11 can't do space-between or flex-grow with min-height (needs height). This makes sure the tagline is readable */
	padding-top: 1px; /* Fixes Safari issue where top 1px of text didn't get the hoverstate color */
	transform: translateY(1px); /* visually hide padding-top pixel */
	transition: color 96ms linear;
}

.beer-teaser__link:focus .beer-teaser__title,
.beer-teaser__link:hover .beer-teaser__title {
	color: var(--color-white);
}

.theme-black .beer-teaser__link:focus .beer-teaser__title,
.theme-black .beer-teaser__link:hover .beer-teaser__title,
.theme-blue-dark .beer-teaser__link:focus .beer-teaser__title,
.theme-blue-dark .beer-teaser__link:hover .beer-teaser__title {
	color: var(--color-black);
}

.beer-teaser__tagline {
	display: inline-block;
}

@media (--mq-up-to-small) {
	.beer-teaser__tagline {
		max-width: 200px;
	}
}

.beer-teaser__thumbnail-container {
	display: flex;
	overflow: visible;
	justify-content: center;
	pointer-events: none;
	position: absolute;
	top: calc(50% - 0.75rem);
	left: 50%;
	transform: translate(-50%, -50%);
	transition: transform 320ms ease-out;
	user-select: none;
	white-space: nowrap;
	z-index: 3;
}

.beer-teaser:hover .beer-teaser__thumbnail-container {
	transition: none;
}

.beer-teaser__thumbnail {
	display: inline-block;
	flex-basis: 2.75rem; /* IE11 won't follow width without flex-basis */
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: var(--space-10);
	margin-left: var(--space-10);
	transform-origin: top center;
	transition: transform 280ms ease-out;
	width: 2.75rem;
	height: auto;
	max-height: 7.75rem;
}

@media (--mq-from-small) and (--mq-up-to-wide) {
	.beer-teaser__thumbnail {
		margin-right: 0.6875rem;
		margin-left: 0.6875rem;
	}
}

.beer-teaser:hover .beer-teaser__thumbnail {
	transition: transform 280ms linear;
}

.beer-teaser__thumbnail__asset {
	display: block;
	max-height: 100%;
}

@supports (object-fit: contain) {
	.beer-teaser__thumbnail__asset {
		object-fit: contain;
		width: 100%;
		height: 100%;
	}
}
