.call-to-action {
	box-sizing: border-box;
	display: block;
	padding: var(--space-15) var(--space-40);
	text-decoration: none;
}

@media (--mq-from-small) {
	.call-to-action {
		padding: var(--space-15) var(--space-20);
	}
}

@media (--mq-from-wide) {
	.call-to-action {
		padding: var(--space-40) var(--space-50);
	}
}

.call-to-action__inner {
	display: flex;
	align-items: center;
}

@media (--mq-up-to-small) {
	.call-to-action__inner {
		flex-direction: column;
		margin-right: auto;
		margin-left: auto;
		max-width: 200px;
	}
}

.call-to-action__text-1,
.call-to-action__text-2 {
	margin-bottom: 0;
	text-transform: uppercase;
}

@media (--mq-up-to-small) {
	.call-to-action__text-1,
	.call-to-action__text-2 {
		text-align: center;
	}
}

@media (--mq-from-small) {
	.call-to-action__text-1,
	.call-to-action__text-2 {
		flex-basis: calc((100% - 150px - var(--space-30) - var(--space-30)) / 2); /* (available space - image width - spacings) / 2 */
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (--mq-from-wide) {
	.call-to-action__text-1,
	.call-to-action__text-2 {
		flex-basis: calc((100% - 215px - var(--space-20) - var(--space-20)) / 2);
	}
}

@media (--mq-from-small) {
	.call-to-action__text-1 {
		margin-right: auto;
	}
}

@media (--mq-from-small) {
	.call-to-action__text-2 {
		margin-left: auto;
		text-align: right;
	}
}

.call-to-action__picture {
	margin: calc(var(--space-10) * -1) auto;
	width: 150px;
}

@media (--mq-from-small) {
	.call-to-action__picture {
		margin-top: calc(var(--space-15) * -1 - var(--space-10));
		margin-bottom: calc(var(--space-15) * -1 - var(--space-10));
	}
}

@media (--mq-from-wide) {
	.call-to-action__picture {
		margin-top: calc(var(--space-40) * -1 - var(--space-15));
		margin-bottom: calc(var(--space-40) * -1 - var(--space-15));
		width: 215px;
	}
}

.call-to-action:focus .call-to-action__picture,
.call-to-action:hover .call-to-action__picture {
	animation: SHAKE 112ms ease-in-out infinite;
}

.call-to-action__image {
	position: relative;
	z-index: 2;
}
