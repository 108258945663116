.playa-banner {
	color: currentColor;
	display: block;
	overflow: hidden;
	position: relative;
	text-decoration: none;
	width: 100%;
}

@media (--mq-from-small) {
	.playa-banner {
		height: 278px;
	}
}

@media (--mq-from-wide) {
	.playa-banner {
		height: 400px;
	}
}

@supports (clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0)) or (-webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0)) {
	.playa-banner {
		overflow: visible;
	}
}

/** CONTENT
 * — overline
 * — title
 */
.playa-banner__content {
	box-sizing: border-box;
	padding: var(--space-25);
	position: relative;
	text-align: center;
	z-index: 9;
}

@media (--mq-from-small) {
	.playa-banner__content {
		padding: var(--space-30);
		position: absolute;
		top: 0;
		right: 0;
		width: 50%;
	}
}

/* — overline */
.playa-banner__overline {
	font-family: var(--font-title);
	font-size: 1.125rem;
	font-weight: var(--font-weight-regular);
	line-height: 1.11;
	margin-bottom: var(--space-15);
	text-transform: uppercase;
}

/* — title */
.playa-banner__title {
	transition: color 96ms linear;
}

.playa-banner:focus .playa-banner__title,
.playa-banner:hover .playa-banner__title {
	color: var(--color-red);
}

/** VISUALS
 * — bottle
 * — illustrations
 * — — sun
 * — — flamingo
 * — — waves
 * — — — waves back
 * — — — waves front
 */
.playa-banner__visuals {
	pointer-events: none;
	position: static;
	width: 100%;
}

@media (--mq-from-small) {
	.playa-banner__visuals {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		z-index: 1;
	}
}

/* — bottle */
.playa-banner__bottle {
	display: block;
	margin-left: 45px;
	position: relative;
	z-index: 4;
}

@media (--mq-from-small) {
	.playa-banner__bottle {
		margin-left: 0;
		position: absolute;
		bottom: 0;
		left: 275px;
	}
}

@media (--mq-from-wide) {
	.playa-banner__bottle {
		margin-left: 0;
		position: absolute;
		bottom: 0;
		left: 300px;
	}
}

.playa-banner__bottle__image {
	animation: BOB 4000ms infinite ease-in-out;
	display: block;
	transform: rotate(-10deg) translate3d(0, 20px, 0);
	transform-origin: calc(100% - 60px) 50%;
	width: 120px;
}

@media (--mq-from-small) and (--mq-up-to-wide) {
	.playa-banner__bottle__image {
		animation: BOB_SMALL 4000ms infinite ease-in-out;
		transform: rotate(-10deg) translate3d(0, -5px, 0);
		width: 75px;
	}
}

@supports (clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0)) or (-webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0)) {
	.playa-banner__bottle__image {
		clip-path: polygon(0 0, 100% 0%, 100% calc(100% - 50px), 0% calc(100% - 50px));
	}

	@media (--mq-from-small) and (--mq-up-to-wide) {
		.playa-banner__bottle__image {
			clip-path: polygon(0 0, 100% 0%, 100% calc(100% - 20px), 0% calc(100% - 20px));
		}
	}
}

@keyframes BOB {
	0%,
	100% {
		transform: rotate(-5deg) translate3d(0, 20px, 0);
	}

	25% {
		transform: rotate(-4deg) translate3d(0, 40px, 0);
	}

	50% {
		transform: rotate(-2deg) translate3d(0, 15px, 0);
	}

	75% {
		transform: rotate(-3deg) translate3d(0, 40px, 0);
	}
}

@keyframes BOB_SMALL {
	0%,
	100% {
		transform: rotate(-5deg) translate3d(0, -5px, 0);
	}

	25% {
		transform: rotate(-4deg) translate3d(0, 10px, 0);
	}

	50% {
		transform: rotate(-2deg) translate3d(0, -8px, 0);
	}

	75% {
		transform: rotate(-3deg) translate3d(0, 10px, 0);
	}
}

/* — illustrations */
.playa-banner__flamingo-container,
.playa-banner__sun-container,
.playa-banner__waves-back-container,
.playa-banner__waves-front-container {
	overflow: hidden;
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* — — sun */
.playa-banner__sun-container {
	z-index: 1;
}

.playa-banner__sun {
	background-color: var(--color-yellow);
	border-radius: 50%;
	position: absolute;
	top: 60px;
	right: -100px;
	width: 200px;
	height: 200px;
}

@media (--mq-from-small) {
	.playa-banner__sun {
		top: -100px;
		right: auto;
		left: 75px;
	}
}

@media (--mq-from-small) {
	.playa-banner__sun {
		top: -60px;
		left: 135px;
	}
}

/* — — flamingo */
.playa-banner__flamingo-container {
	z-index: 3;
}

.playa-banner__flamingo {
	display: block;
	position: absolute;
	top: 100%;
	right: -30px;
	transition: transform 800ms cubic-bezier(0.42, 0, 0.15, 1);
	width: 110px;
	height: auto;
}

@media (--mq-from-small) {
	.playa-banner__flamingo {
		right: auto;
		left: 50px;
		width: 100px;
	}
}

@media (--mq-from-wide) {
	.playa-banner__flamingo {
		right: 175px;
		left: auto;
		width: 130px;
	}
}

.playa-banner:focus .playa-banner__flamingo,
.playa-banner:hover .playa-banner__flamingo {
	transform: translateY(-180px);
}

@media (--mq-from-small) {
	.playa-banner:focus .playa-banner__flamingo,
	.playa-banner:hover .playa-banner__flamingo {
		transform: translateY(-200px);
	}
}

@media (--mq-from-wide) {
	.playa-banner:focus .playa-banner__flamingo,
	.playa-banner:hover .playa-banner__flamingo {
		transform: translateY(-240px);
	}
}

/* — — waves */
.playa-banner__waves-back,
.playa-banner__waves-front {
	background-position: top left;
	background-repeat: repeat-x;
	background-size: var(--wave-width) var(--wave-height);
	position: absolute;
	width: calc(100% + (var(--wave-width) * 4));
	height: var(--wave-height);
}

/* — — waves back */
.playa-banner__waves-back-container {
	z-index: 2;
}

.playa-banner__waves-back {
	animation: WAVE_TO_RIGHT 7000ms infinite linear;
	background-image: url("../images/playa-banner/wave-blue-dark.svg");
	bottom: 16px;
	left: calc(var(--wave-width) * -2.5);
}

@keyframes WAVE_TO_RIGHT {
	0% {
		transform: translate3d(0, 0, 0);
	}

	50% {
		transform: translate3d(var(--wave-width), 0, 0);
	}

	100% {
		transform: translate3d(calc(var(--wave-width) * 2), 0, 0);
	}
}

/* — — waves front */
.playa-banner__waves-front-container {
	z-index: 5;
}

.playa-banner__waves-front {
	animation: WAVE_TO_LEFT 3800ms infinite linear;
	background-image: url("../images/playa-banner/wave-blue-light.svg");
	bottom: -25px;
	left: calc(var(--wave-width) * -2);
}

@keyframes WAVE_TO_LEFT {
	0% {
		transform: translate3d(0, 0, 0);
	}

	50% {
		transform: translate3d(var(--wave-width), 0, 0);
	}

	100% {
		transform: translate3d(calc(var(--wave-width) * 2), 0, 0);
	}
}
