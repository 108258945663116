.contest-bavet-hero {
	box-sizing: border-box;
	display: block;
	padding: var(--space-10);
	text-align: center;
}

@media (--mq-from-small) {
	.contest-bavet-hero {
		padding: var(--space-35) var(--space-25);
	}
}

@media (--mq-from-wide) {
	.contest-bavet-hero {
		padding: var(--space-40);
	}
}

.contest-bavet-hero__title {
	color: var(--color-black);
	font-family: var(--font-sans);
	font-size: 2.5rem;
	font-weight: var(--font-weight-black);
	line-height: 0.88;
}

@media (--mq-from-wide) {
	.contest-bavet-hero__title {
		font-size: 5.625rem;
		line-height: 0.78;
	}
}

.contest-bavet-hero__paragraph {
	color: var(--color-black);
	margin-right: auto;
	margin-left: auto;
	max-width: 756px;
}

.contest-bavet-hero__link {
	color: var(--color-black);
}

.contest-bavet-hero__link:hover,
.contest-bavet-hero__link:focus {
	color: var(--color-white);
}
