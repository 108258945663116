.beers-hero {
	display: block;
	overflow: hidden;
	position: relative;
	width: 100%;
	height: calc((100vw - 2.5rem) / 280 * 220);
} /* maintain this ratio on mobile */

@media (--mq-from-small) {
	.beers-hero {
		height: 17.5rem;
	}
}

@media (--mq-from-wide) {
	.beers-hero {
		height: 25rem;
	}
}

.beers-hero__video-mobile {
	display: block;
	width: 100%;
	height: auto;
}

@media (--mq-from-small) {
	.beers-hero__video-mobile {
		display: none;
	}
}

.beers-hero__video {
	display: none;
	width: 100%;
	height: auto;
}

@media (--mq-from-small) {
	.beers-hero__video {
		display: block;
	}
}

.beers-hero__title-container {
	position: absolute;
	top: 0;
	left: 0;
}

.beers-hero__title {
	max-width: 420px;
}

@media (--mq-from-wide) {
	.beers-hero__title {
		max-width: 560px;
	}
}
