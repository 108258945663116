.beer-hero {
	position: relative;
	width: 100%;
}

@media (min-width: 768px) {
	.beer-hero {
		display: flex;
		flex-wrap: wrap;
	}
}

@media (min-width: 768px) {
	.beer-hero__item {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
		min-height: 364px; /* site container is 728px here, so taking half makes this at square at minimum height */
	}
}

@media (min-width: 1280px) {
	.beer-hero__item {
		min-height: 520px; /* site container is 1040px here, so taking half makes this at square at minimum height */
	}
}

.beer-hero__item.beer-hero__item--tagline-animal {
	background-color: var(--color-background);
	color: var(--color-foreground);
}

@media (min-width: 768px) {
	.beer-hero__item.beer-hero__item--tagline-animal {
		order: 1;
	}
}

@media (min-width: 768px) {
	.beer-hero__item.beer-hero__item--thumbnail {
		order: 2;
	}
}

.beer-hero__item.beer-hero__item--description {
	background-color: var(--color-background);
	color: var(--color-foreground);
}

@media (min-width: 768px) {
	.beer-hero__item.beer-hero__item--description {
		order: 4;
	}
}

@media (min-width: 768px) {
	.beer-hero__item.beer-hero__item--details {
		order: 3;
	}
}

.beer-hero__item__inner {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding: var(--space-20);
	justify-content: center;
	align-items: center;
	width: 100%;
}

@media (min-width: 768px) {
	.beer-hero__item__inner {
		height: 100%;
	}
}

@media (min-width: 1280px) {
	.beer-hero__item__inner {
		padding: var(--space-70);
	}
}

@media (max-width: 767px) {
	.beer-hero__item--tagline-animal .beer-hero__item__inner,
	.beer-hero__item--thumbnail .beer-hero__item__inner {
		min-height: calc(100vw - (var(--space-20) * 2)); /* take width of site container as height for square at minimum height */
	}
}

.beer-hero__item__inner > * {
	max-width: 100%; /* force line breaks in IE11 / avoid overflow */
}

/* TAGLINE + ANIMAL */
.beer-hero__tagline {
	text-align: center;
}

.beer-hero__tagline:not(:last-child) {
	margin-bottom: calc(var(--space-20) - var(--type-a-leveler));
}

@media (min-width: 1280px) {
	.beer-hero__tagline:not(:last-child) {
		margin-bottom: calc(var(--space-20) - var(--type-a-leveler-from-wide));
	}
}

.beer-hero__animal {
	width: 62px;
	height: 62px;
}

@media (min-width: 1280px) {
	.beer-hero__animal {
		width: 86px;
		height: 86px;
	}
}

/* THUMBNAIL */
.beer-hero__thumbnail {
	display: inline-block;
	margin-right: auto;
	margin-left: auto;
	width: 4.75rem;
	height: 13.5rem;
}

@media (min-width: 768px) {
	.beer-hero__thumbnail {
		width: 5.5rem;
		height: 15.5rem;
	}
}

@media (min-width: 1280px) {
	.beer-hero__thumbnail {
		width: 7.875rem;
		height: 22.25rem;
	}
}

.beer-hero__thumbnail__asset {
	display: block;
	margin-right: auto;
	margin-left: auto;
	max-height: 100%;
}

@supports (object-fit: contain) {
	.beer-hero__thumbnail__asset {
		object-fit: contain;
		width: 100%;
		height: 100%;
	}
}

/* DETAILS */
.beer-hero__details {
	width: 100%;
}

.beer-hero__details__row {
	display: inline-flex;
	padding-top: calc(var(--space-10) + 0.125rem); /* fix vertical alignment of title font */
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
}

.beer-hero__details__row:not(:last-child) {
	border-bottom: 1px solid;
	padding-bottom: var(--space-10);
}

.beer-hero__details__key {
	display: inline-block;
	flex-basis: auto;
	flex-grow: 1;
	transform: translateY(0.125rem); /* fix vertical alignment of title font */
}

.beer-hero__details__value {
	flex-basis: 60%;
	flex-grow: 0;
	flex-shrink: 0;
	margin-left: var(--space-10);
	text-align: right;
}
