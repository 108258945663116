.privacy-control__section:not(:last-child) {
	padding-bottom: var(--space-30);
}

@media (min-width: 1440px) {
	.privacy-control__section:not(:last-child) {
		padding-bottom: var(--space-40);
	}
}
/* see : .u-visually-hidden */
.privacy-control__section .form__radio__input {
	border: none !important;
	clip: rect(0 0 0 0) !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	white-space: nowrap !important;
	width: 1px !important;
	height: 1px !important;
}

.privacy-control__columns {
	display: block;
}

@media (min-width: 1024px) {
	.privacy-control__columns {
		display: flex;
		flex-direction: row;
	}
}

.privacy-control__columns:not(:last-child) {
	padding-bottom: var(--space-20);
}

@media (min-width: 1440px) {
	.privacy-control__columns:not(:last-child) {
		padding-bottom: var(--space-30);
	}
}

.privacy-control__column {
	flex-basis: 50%;
	padding-bottom: var(--space-20);
}

@media (min-width: 1024px) {
	.privacy-control__column:not(:last-child) {
		padding-bottom: 0;
	}
}

/* Google Ads and Facebook Pixel follow Do Not Track through GTM config */

/* TODO : GTM config */

/* [mr-prv-gtm-control][name="gr"][do-not-track="1"] + label,
[mr-prv-gtm-control][name="fp"][do-not-track="1"] + label {
	text-decoration: line-through;
} */
