.sttupid-grid {
	display: flex;
	flex-wrap: wrap;
}

.sttupid-grid__banner {
	display: flex;
	flex-direction: column;
	padding-right: var(--space-20);
	padding-left: var(--space-20);
	align-items: center;
	text-align: center;
	width: 100%;
}

@media (min-width: 768px) {
	.sttupid-grid__banner {
		flex-direction: row;
	}
}

.sttupid-grid__banner.sttupid-grid__banner--reversed {
	flex-direction: column-reverse;
}

@media (min-width: 768px) {
	.sttupid-grid__banner.sttupid-grid__banner--reversed {
		flex-direction: row-reverse;
	}
}

.sttupid-grid__banner:nth-of-type(1) {
	order: 1;
}

.sttupid-grid__banner:nth-of-type(2) {
	order: 3;
}

.sttupid-grid__banner__title,
.sttupid-grid__banner__button {
	margin-top: var(--space-20) !important;
	margin-right: auto;
	margin-bottom: var(--space-20) !important;
	margin-left: auto;
}

@media (min-width: 768px) {
	.sttupid-grid__banner__title,
	.sttupid-grid__banner__button {
		margin-top: var(--space-50) !important;
		margin-bottom: var(--space-50) !important;
	}
}

@media (min-width: 1280px) {
	.sttupid-grid__banner__title,
	.sttupid-grid__banner__button {
		margin-top: var(--space-70) !important;
		margin-bottom: var(--space-70) !important;
	}
}

@media (max-width: 767px) {
	.sttupid-grid__banner:not(.sttupid-grid__banner--reversed) .sttupid-grid__banner__title ~ .sttupid-grid__banner__button {
		margin-top: 0 !important;
	}
}

@media (max-width: 767px) {
	.sttupid-grid__banner.sttupid-grid__banner--reversed .sttupid-grid__banner__title ~ .sttupid-grid__banner__button {
		margin-bottom: 0 !important;
	}
}

.sttupid-grid__post {
	flex-basis: 100%;
}

@media (min-width: 768px) {
	.sttupid-grid__post {
		flex-basis: 20%;
		flex-shrink: 1;
	}
}

.sttupid-grid__post:nth-of-type(-n + 5) {
	order: 0;
}

.sttupid-grid__post:nth-of-type(n + 6) {
	order: 2;
}

.sttupid-grid__post:nth-of-type(n + 21) {
	order: 4;
}

.sttupid-grid__link {
	background-color: var(--color-white);
	color: currentColor;
	display: flex;
	text-decoration: none;
	transition:
		background-color 96ms linear,
		color 96ms linear;
	height: 100%;
}

@media (max-width: 767px) {
	.sttupid-grid__link {
		align-items: center;
	}
}

@media (min-width: 768px) {
	.sttupid-grid__link {
		box-sizing: border-box;
		flex-direction: column;
		padding: 0.3125rem;
	}
}

.sttupid-grid__link:focus,
.sttupid-grid__link:hover {
	background-color: var(--color-black);
	color: var(--color-white);
}

.sttupid-grid__post__title {
	margin-top: 0;
}

@media (max-width: 767px) {
	.sttupid-grid__post__title {
		padding-top: 0.3125rem;
		padding-right: 0.3125rem;
		padding-left: 0.3125rem;
	}
}

@media (min-width: 768px) {
	.sttupid-grid__post__title {
		margin-top: 0.3125rem;
	}
}

@media (min-width: 1280px) {
	.sttupid-grid__post__title {
		margin-top: var(--space-10);
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}
}
