.sttupid-modal {
	display: block;
	position: relative;
	width: 100%;
}

@media (min-width: 768px) {
	.sttupid-modal {
		width: 600px;
	}
}

@media (min-width: 1024px) {
	.sttupid-modal {
		width: 800px;
	}
}

.sttupid-modal__header {
	box-sizing: border-box;
	display: flex;
	padding: var(--space-15) 4rem 0.3125rem var(--space-20);
	width: 100%;
}

@media (min-width: 1280px) {
	.sttupid-modal__header {
		padding: var(--space-30) 6.75rem var(--space-20) var(--space-50);
	}
}

@media (min-width: 1280px) {
	.sttupid-modal__video {
		box-sizing: border-box;
		display: block;
		padding-right: var(--space-50);
		padding-left: var(--space-50);
		width: 100%;
	}
}

.sttupid-modal__content {
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	padding: var(--space-20);
	align-items: flex-start;
	width: 100%;
}

@media (max-width: 767px) {
	.sttupid-modal__content {
		align-items: center;
	}
}

@media (min-width: 768px) {
	.sttupid-modal__content {
		padding: var(--space-20) var(--space-20) var(--space-10);
	}
}

@media (min-width: 1280px) {
	.sttupid-modal__content {
		padding: var(--space-30) var(--space-50) var(--space-20);
	}
}

@media (max-width: 767px) {
	.sttupid-modal__description {
		width: 100%;
	}
}

@media (min-width: 768px) {
	.sttupid-modal__description {
		flex-basis: 0;
		flex-grow: 1;
		margin-right: var(--space-20);
	}
}

.sttupid-modal__hashtag-link-container {
	margin-bottom: 0;
}

@media (min-width: 768px) {
	.sttupid-modal__description ~ .sttupid-modal__hashtag-link-container {
		order: 2;
		width: 100%;
	}
}

.sttupid-modal__hashtag-link {
	color: currentColor;
	text-decoration: none;
	transition: color 96ms linear;
}

.sttupid-modal__hashtag-link:focus,
.sttupid-modal__hashtag-link:hover {
	color: var(--color-red);
}

.sttupid-modal__share {
	margin-left: auto;
	text-align: right;
}

.sttupid-modal__share__text {
	margin-bottom: 0;
}

.sttupid-modal__share__icon {
	display: inline-block;
	margin-left: var(--space-10);
	transition: opacity 96ms linear;
	vertical-align: middle;
}

.sttupid-modal__share__icon::before {
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	content: "";
	display: block;
}

.sttupid-modal__share__icon[href*="facebook.com"]::before {
	background-image: url("../images/share-facebook.svg");
	width: 20px;
	height: 20px;
}

.sttupid-modal__share__icon[href*="twitter.com"]::before {
	background-image: url("../images/share-twitter.svg");
	width: 20px;
	height: 18px;
}

.sttupid-modal__share__icon:focus,
.sttupid-modal__share__icon:hover {
	opacity: 0.5;
}

.sttupid-modal__close {
	line-height: 1;
	position: absolute;
	text-decoration: none;
	top: var(--space-20);
	right: var(--space-20);
}

@media (min-width: 1280px) {
	.sttupid-modal__close {
		top: var(--space-40);
		right: var(--space-50);
	}
}
