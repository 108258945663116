/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.youtube-embed {
	position: relative;
	width: 100%;
	height: 100%;
}

.youtube-embed:hover .button {
	/* button has no pointer events, shake it when we have hover on the whole */
	animation: SHAKE 112ms ease-in-out infinite;
}

:root.no-js .youtube-embed {
	display: none;
}

.youtube-embed__play {
	display: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* We need to make the button a bit large to make sure we cover the native play */

.youtube-embed__play .button {
	/* special case : "View Movie" goes over two lines on abnormally small screens */
	padding: 15px 15px calc(15px - var(--type-b-leveler)) 15px;
}

/* special case : "View Movie" fits on one line from here */
@media (min-width: 230px) {
	.youtube-embed__play .button {
		padding: 22px 22px calc(22px - var(--type-b-leveler)) 22px;
	}
}

.youtube-embed__iframe {
	width: 100%;
	height: 100%;
}

.youtube-embed__iframe[mrh-yt-playback-state="-1"] + .youtube-embed__play {
	/* unstarted */
	display: block;
}

/*
	 * Disable pointer events on the fake play button that covers the native youtube play button.
	 * Disable pointer events only if the user has accepted YouTube as a tracking embed provider.
	 * Otherwise we use the button to trigger a modal open with an accept/decline flow.
	 */

.youtube-embed__iframe:not([mr-prv-embedder-blocked]) + .youtube-embed__play {
	pointer-events: none;
}

/* Disable pointer events on iframe when the user hasn't accepted youtube as a tracking embed provider. */

.youtube-embed__iframe[mr-prv-embedder-blocked] {
	pointer-events: none;
}

:root:not(.no-js) .youtube-embed-no-js {
	display: none;
}

.youtube-link {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
