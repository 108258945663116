.superett-banner-mobile {
	margin: 0;
	padding: 0;
	position: relative;
	width: 100%;
}

@media (--mq-from-small) {
	.superett-banner-mobile {
		display: none;
	}
}

.superett-banner-mobile__item {
	position: relative;
	z-index: 1;
}

.superett-banner-mobile__heading {
	margin-bottom: 0 !important;
	padding: 0;
}

.superett-banner-mobile__trigger {
	background: none;
	border: 0;
	border-radius: 0;
	box-sizing: border-box;
	color: currentColor;
	display: block;
	outline: none;
	padding: var(--space-12) var(--space-20);
	text-align: left;
	width: 100%;
}

.superett-banner-mobile__trigger[aria-expanded="true"] {
	display: none;
}

.superett-banner-mobile__title {
	display: block;
	outline: none;
	overflow: hidden;
	pointer-events: none;
	text-overflow: ellipsis;
	transform: translateY(0.25rem);
	white-space: nowrap;
	max-width: calc(100vw - (var(--space-20) * 4)); /* site width - wrapper padding - inner padding */
}

html:not(.no-js, .js-loading-error) .superett-banner-mobile__panel[hidden] {
	display: none;
}

@supports (content-visibility: hidden) {
	html:not(.no-js, .js-loading-error) .superett-banner-mobile__panel[hidden="until-found"] {
		content-visibility: hidden;
		display: block;
		padding: 0;
	}
}

.superett-banner-mobile__sticker {
	pointer-events: none;
	position: absolute;
	top: 1.5rem;
	right: 0.8125rem;
	transform: rotate(-5deg);
	user-select: none;
	width: 127px;
	height: 60px;
	z-index: 4;
}
