.contact-hero {
	box-sizing: border-box;
	display: block;
	padding: var(--space-10);
	width: 100%;
}

@media (--mq-from-small) {
	.contact-hero {
		padding: var(--space-20);
	}
}

@media (--mq-from-wide) {
	.contact-hero {
		padding: var(--space-40);
	}
}

.contact-hero__title {
	font-family: var(--font-sans);
	font-size: 1.875rem;
	font-weight: var(--font-weight-bold);
	line-height: 0.93;
	text-transform: uppercase;
}

@media (--mq-from-small) {
	.contact-hero__title {
		font-size: 3.125rem;
		line-height: 0.96;
	}
}

@media (--mq-from-wide) {
	.contact-hero__title {
		font-size: 3.75rem;
		line-height: 0.86;
	}
}

.contact-hero__spirit-animal {
	cursor: pointer;
	display: inline-block;
	font-size: 1.25rem;
	position: relative;
	text-align: center;
	vertical-align: middle;
	width: 1.25rem;
}

@media (--mq-from-small) {
	.contact-hero__spirit-animal {
		font-size: 2rem;
		width: 2.5rem;
	}
}

@media (--mq-from-wide) {
	.contact-hero__spirit-animal {
		font-size: 2.5rem;
		width: 3.75rem;
	}
}

.contact-hero__spirit-animal__image,
.contact-hero__spirit-animal__picture {
	display: block;
	width: 5.625rem; /* 90px */
	height: 5.625rem;
}

/* 90px */
@media (--mq-from-wide) {
	.contact-hero__spirit-animal__image,
	.contact-hero__spirit-animal__picture {
		width: 12.5rem; /* 200px */
		height: 12.5rem; /* 200px */
	}
}

.contact-hero__spirit-animal__picture {
	animation:
		SPIRIT_ANIMAL_OPACITY_IN 96ms linear 512ms forwards,
		SPIRIT_ANIMAL_FLY_IN 720ms cubic-bezier(0.57, 0, 0, 1) 512ms forwards;
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: rotate(0) translate(-40%, -55%);
	z-index: 20;
}

@media (--mq-from-large) {
	.contact-hero__spirit-animal__picture {
		animation:
			SPIRIT_ANIMAL_OPACITY_IN 96ms linear 592ms forwards,
			SPIRIT_ANIMAL_FLY_IN 800ms cubic-bezier(0.57, 0, 0, 1) 512ms forwards;
	}
}

@media (--mq-from-xlarge) {
	.contact-hero__spirit-animal__picture {
		animation:
			SPIRIT_ANIMAL_OPACITY_IN 96ms linear 688ms forwards,
			SPIRIT_ANIMAL_FLY_IN 896ms cubic-bezier(0.57, 0, 0, 1) 512ms forwards;
	}
}

#spirit-animal:checked + .contact-hero__spirit-animal .contact-hero__spirit-animal__picture {
	animation: SPIRIT_ANIMAL_OPACITY_OUT 96ms linear forwards;
	opacity: 1;
}

.contact-hero__spirit-animal__image {
	transition: opacity 96ms linear;
	max-width: none;
}

#spirit-animal:focus + .contact-hero__spirit-animal .contact-hero__spirit-animal__image,
.contact-hero__spirit-animal:hover .contact-hero__spirit-animal__image {
	filter: drop-shadow(2px 4px 8px var(--color-blue-dark));
}

@keyframes SPIRIT_ANIMAL_OPACITY_IN {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes SPIRIT_ANIMAL_OPACITY_OUT {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes SPIRIT_ANIMAL_FLY_IN {
	0% {
		transform: rotate(-30deg) translate(50vw, 50vw);
	}

	100% {
		transform: rotate(0) translate(-40%, -55%);
	}
}
