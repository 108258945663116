.navigation-list {
	display: flex;
	flex-direction: column;
	font-size: 0;
	list-style: none;
	margin: 0;
	padding-left: 0;
	text-align: inherit;
}

.navigation-list__item {
	width: 100%;
}

.navigation-list__link {
	color: currentColor;
	display: inline-block;
	font-family: var(--font-title);
	font-size: 1rem;
	font-style: normal;
	font-weight: var(--font-weight-regular);
	line-height: 1.63;
	overflow: hidden;
	position: relative;
	text-decoration: none;
	text-transform: uppercase;
	width: auto;
	width: max-content;
}

@media (max-width: 767px) {
	.navigation-list__link {
		padding-top: 0.25rem; /* avoid SEO penalty for tiny tap targets */
		padding-bottom: 0.25rem; /* avoid SEO penalty for tiny tap targets */
	}
}

@media (min-width: 1280px) {
	.navigation-list__link {
		font-size: 1.125rem;
		line-height: 1.67;
	}
}

.navigation-list__link::after {
	background-color: var(--color-green);
	content: "";
	opacity: 0;
	pointer-events: none;
	position: absolute;
	top: calc(50% - 0.1875rem);
	left: 0;
	transition: opacity 64ms linear;
	width: 100%;
	height: 0.3125rem;
	z-index: 2;
}

@media (min-width: 768px) {
	.navigation-list__link::after {
		top: calc(50% - 0.125rem);
	}
}

.navigation-list__item.is-active .navigation-list__link::after,
.navigation-list__link:focus::after,
.navigation-list__link:hover::after {
	opacity: 1;
}

.navigation-list__label {
	display: inline-block;
	line-height: 1;
	position: relative;
	transform: translateY(0.125rem); /* level out Gill Sans offset alignment */
	z-index: 1;
}

@media (min-width: 1280px) {
	.navigation-list__label {
		transform: translateY(0.25rem);
	}
}
