.sttupid-strike-through {
	display: inline-block;
	position: relative;
}

.sttupid-strike-through::after {
	color: var(--color-black);
	content: attr(data-strike-through-text);
	opacity: 0;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	transition: opacity 64ms linear;
	z-index: 2;
}

.sttupid-strike-through::before {
	background-color: var(--color-green);
	content: "";
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-100%) scale3d(0, 1, 1);
	transform-origin: left top;
	transition: transform 256ms var(--ease-out-quad);
	width: 100%;
	height: 0.75rem;
	z-index: 1;
}

@media (min-width: 1280px) {
	.sttupid-strike-through::before {
		transform: translateY(-80%) scale3d(0, 1, 1);
		height: 1.25rem;
	}
}

a:focus .sttupid-strike-through::after,
a:hover .sttupid-strike-through::after,
.sttupid-strike-through.sttupid-strike-through--always-show::after {
	opacity: 1;
}

a:focus .sttupid-strike-through::before,
a:hover .sttupid-strike-through::before,
.sttupid-strike-through.sttupid-strike-through--always-show::before {
	transform: translateY(-100%) scale3d(1, 1, 1);
}

@media (min-width: 1280px) {
	a:focus .sttupid-strike-through::before,
	a:hover .sttupid-strike-through::before,
	.sttupid-strike-through.sttupid-strike-through--always-show::before {
		transform: translateY(-80%) scale3d(1, 1, 1);
	}
}
