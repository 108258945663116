.superett-banner {
	overflow: hidden;
	position: relative;
	width: 100%;
}

@media (--mq-up-to-small) {
	.superett-banner {
		display: none;
	}
}

.superett-banner__background,
.superett-banner__items {
	display: flex;
	width: 100%;
}

.superett-banner__items {
	position: relative;
	z-index: 3;
}

.superett-banner__background {
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% + 1px); /* helps avoiding a white line on the right because of pixel rounding */
	height: 100%;
	z-index: 1;
}

.superett-banner__background__item,
.superett-banner__item {
	flex-basis: 33.3333%;
	flex-grow: 0;
	flex-shrink: 0;
}

.superett-banner__sticker {
	pointer-events: none;
	position: absolute;
	top: 45%;
	left: 33.3333%;
	transform: rotate(-5deg) translateX(-50%);
	user-select: none;
	width: 127px;
	height: 60px;
	z-index: 2;
}

@media (--mq-from-wide) {
	.superett-banner__sticker {
		top: 50%;
		left: calc(33.3333% + 0.5rem);
		transform: rotate(-7deg) translate(-50%, -50%);
		width: 169px;
		height: 80px;
	}
}
