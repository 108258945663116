.modal-dialog {
	display: none;
	opacity: 0;
	pointer-events: none;
}

.modal-dialog[data-state="opening"],
.modal-dialog[data-state="open"],
.modal-dialog[data-state="closing"],
.l-styleguide .modal-dialog {
	display: block;
	opacity: 1;
	pointer-events: auto;
}

.modal-dialog:focus {
	outline: none;
}

.l-styleguide .modal-dialog {
	position: relative;
	width: 100%;
	height: 100vh;
}

.modal-dialog__content {
	display: block;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: calc(100% - (var(--space-20) * 2));
}

@media (--mq-from-small) {
	.modal-dialog__content {
		width: auto;
		max-width: 600px;
	}
}

@media (--mq-from-medium) {
	.modal-dialog__content {
		max-width: 800px;
	}
}

#age-gate-modal .modal-dialog__content {
	z-index: var(--z-index-age-gate-md-fg);
}

#privacy-alert-youtube-modal .modal-dialog__content {
	max-width: 288px;
	z-index: var(--z-index-privacy-alert-youtube-md-fg);
}

@media (--mq-from-small) {
	#privacy-alert-youtube-modal .modal-dialog__content {
		max-width: 302px;
	}
}

#sttupid-modal .modal-dialog__content {
	z-index: var(--z-index-sttupid-md-fg);
}

#label-creator-modal .modal-dialog__content {
	z-index: var(--z-index-label-creator-md-fg);
}

.l-styleguide .modal-dialog__content {
	position: absolute;
}

.modal-dialog__inner {
	background-color: var(--color-background);
	display: block;
	overflow-x: hidden;
	overflow-y: auto;
	position: relative;
	width: 100%;
	max-height: 75vh;
	z-index: 2;
}

@media (--mq-from-small) and (min-height: 580px) {
	.modal-dialog__inner {
		max-height: 500px;
	}
}

@media (--mq-from-small) and (min-height: 680px) {
	.modal-dialog__inner {
		max-height: 600px;
	}
}

@media (--mq-from-wide) and (min-height: 780px) {
	.modal-dialog__inner {
		max-height: 700px;
	}
}

@media (--mq-from-wide) and (min-height: 880px) {
	.modal-dialog__inner {
		max-height: 800px;
	}
}

.modal-dialog__background {
	background-color: var(--color-alpha-foreground-75);
	cursor: pointer;
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

#age-gate-modal .modal-dialog__background {
	z-index: var(--z-index-age-gate-md-bg);
}

#privacy-alert-youtube-modal .modal-dialog__background {
	z-index: var(--z-index-privacy-alert-youtube-md-bg);
}

#sttupid-modal .modal-dialog__background {
	z-index: var(--z-index-sttupid-md-bg);
}

#label-creator-modal .modal-dialog__background {
	cursor: initial;
	z-index: var(--z-index-label-creator-md-bg);
}

/* Dim stacked modal dialog backgrounds */

.modal-dialog[data-state="open"] ~ .modal-dialog[data-state="open"] .modal-dialog__background {
	background-color: var(--color-alpha-foreground-30);
}

.l-styleguide .modal-dialog__background {
	position: absolute;
}
