.l-site {
	background-image: url("../images/default-background.svg");
	background-position: top center;
	background-repeat: repeat-y;
	background-size: 2560px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	justify-content: flex-start;
	align-items: flex-start;
	position: relative;
	width: 100%;
	min-height: 100vh;
}

@media (min-width: 2560px) {
	.l-site {
		background-size: 100%;
	}
}

.l-site__content,
.l-site__footer,
.l-site__header {
	position: relative;
	width: 100%;
}

.l-site__header {
	z-index: var(--z-index-site-header);
}

.l-site__header::after {
	bottom: calc(var(--site-content-overlap) * -1);
}

@media (--mq-from-small) {
	.l-site__header::after {
		bottom: calc(var(--site-content-overlap-from-small) * -1);
	}
}

@media (--mq-from-medium) {
	.l-site__header::after {
		bottom: calc(var(--site-content-overlap-from-medium) * -1);
	}
}

@media (--mq-from-wide) {
	.l-site__header::after {
		bottom: calc(var(--site-content-overlap-from-wide) * -1);
	}
}

.l-site__footer {
	z-index: var(--z-index-site-footer);
}

.l-site__footer::after {
	background-color: inherit;
	content: "";
	position: absolute;
	top: calc(var(--site-content-overlap) * -1);
	left: 0;
	width: 100%;
	height: var(--site-content-overlap);
}

@media (--mq-from-small) {
	.l-site__footer::after {
		top: calc(var(--site-content-overlap-from-small) * -1);
		height: var(--site-content-overlap-from-small);
	}
}

@media (--mq-from-medium) {
	.l-site__footer::after {
		top: calc(var(--site-content-overlap-from-medium) * -1);
		height: var(--site-content-overlap-from-medium);
	}
}

@media (--mq-from-wide) {
	.l-site__footer::after {
		top: calc(var(--site-content-overlap-from-wide) * -1);
		height: var(--site-content-overlap-from-wide);
	}
}

/* #region Site Content */
.l-site__content {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 0;
	z-index: var(--z-index-site-content);
}

.l-site__content::before {
	background-color: var(--color-yellow);
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: var(--site-content-overlap);
	z-index: 1;
}

@media (--mq-from-small) {
	.l-site__content::before {
		height: var(--site-content-overlap-from-small);
	}
}

@media (--mq-from-medium) {
	.l-site__content::before {
		height: var(--site-content-overlap-from-medium);
	}
}

@media (--mq-from-wide) {
	.l-site__content::before {
		height: var(--site-content-overlap-from-wide);
	}
}

/* Modifiers for theme colors on site header and footer */
.l-site__header.theme-sand + .l-site__content::before {
	background-color: var(--color-sand);
}

/* #endregion Site Content */

.l-site__content__inner {
	position: relative;
	z-index: 2; /* places inner site content above yellow pseudo overlap (.l-site__content::before) */
}
