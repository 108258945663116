.privacy-alert {
	border: 4px solid var(--color-blue-light);
}

.privacy-alert__content {
	box-sizing: border-box;
	padding: var(--space-15);
	width: 280px;
}

@media (--mq-from-small) {
	.privacy-alert__content {
		width: 294px;
	}
}

.privacy-alert__content p.type-h {
	/* following design with this custom override, does look better here. */
	line-height: 1.375rem;
}

.privacy-alert__actions {
	display: flex;
	justify-content: space-between;
}

.privacy-alert__actions .button {
	box-sizing: border-box;
	width: 110px;
}

@media (--mq-from-small) {
	.privacy-alert__actions .button {
		width: 120px;
	}
}
