.button-group {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

@media (--mq-from-small) {
	.button-group {
		flex-direction: row;
	}
}

@media (--mq-up-to-small) {
	.button-group > *:not(:first-child) {
		margin-top: var(--space-20);
	}
}

@media (--mq-from-small) {
	.button-group > *:not(:first-child) {
		margin-left: var(--space-20);
	}
}
