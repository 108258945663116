.columns {
	background-color: var(--color-white);
	display: flex;
	flex-wrap: wrap;
}

.columns__item {
	display: block;
	flex-grow: 1;
	flex-shrink: 0;
	overflow: hidden;
	padding-top: calc(100% / 280 * 220); /* maintain this ratio on mobile */
	position: relative;
	width: 100%;
}

@media (--mq-up-to-small) {
	.columns__item {
		flex-basis: 100%;
	}
}

@media (--mq-from-small) {
	.columns__item {
		padding-top: 17.5rem;
	}
}

@media (--mq-from-wide) {
	.columns__item {
		padding-top: 25rem;
	}
}

.columns__item.columns__item--66 {
	flex-basis: calc(100% / 3 * 2);
}

@media (--mq-from-small) {
	.columns__item.columns__item--50 {
		flex-basis: 50%;
	}
}

@media (--mq-up-to-small) {
	.columns__item.columns__item--33 {
		display: none;
	}
}

@media (--mq-from-small) {
	.columns__item.columns__item--33 {
		flex-basis: calc(100% / 3);
	}
}

.columns__item.columns__item--33-visible {
	padding-top: calc(100% / 140 * 81);
}

/* maintain this ratio on mobile */
@media (--mq-up-to-small) {
	.columns__item.columns__item--33-visible {
		flex-basis: calc(100% / 2);
	}
}

@media (--mq-from-small) {
	.columns__item.columns__item--33-visible {
		flex-basis: calc(100% / 3);
	}
}

@media (--mq-from-small) {
	.columns__item.columns__item--33-visible {
		padding-top: 17.5rem;
	}
}

@media (--mq-from-wide) {
	.columns__item.columns__item--33-visible {
		padding-top: 25rem;
	}
}

.columns__item.columns__item--33-visible.columns__item--priority {
	padding-top: calc(100% / 280 * 325);
}

/* maintain this ratio on mobile */
@media (--mq-up-to-small) {
	.columns__item.columns__item--33-visible.columns__item--priority {
		flex-basis: 100%;
		order: -1;
	}
}

@media (--mq-from-small) {
	.columns__item.columns__item--33-visible.columns__item--priority {
		padding-top: 17.5rem;
	}
}

@media (--mq-from-wide) {
	.columns__item.columns__item--33-visible.columns__item--priority {
		padding-top: 25rem;
	}
}

.columns__item.columns__item--25 {
	flex-basis: 50%;
}

@media (--mq-from-small) {
	.columns__item.columns__item--25 {
		flex-basis: 25%;
	}
}

.columns__item:empty {
	display: none;
}

.columns__picture {
	display: block;
	width: 100%;
	height: 100%;
}

.columns__image,
.columns__video,
.columns__video-mobile {
	position: absolute;
	top: 0;
	left: 0;
}

.columns__image {
	display: block;
	width: 100%;
	height: auto;
}

.columns__video,
.columns__video-mobile {
	width: 100%;
	height: auto;
}

@supports (object-fit: contain) {
	.columns__video,
	.columns__video-mobile {
		object-fit: contain;
		height: 100%;
	}
}

.columns__video-mobile {
	display: block;
}

@media (--mq-from-small) {
	.columns__video-mobile {
		display: none;
	}
}

.columns__video {
	display: none;
}

@media (--mq-from-small) {
	.columns__video {
		display: block;
	}
}

.columns__button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.columns__hover-label-link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.columns__hover-label {
	background-color: var(--color-white);
	box-sizing: border-box;
	color: var(--color-black);
	display: none;
	font-family: var(--font-title);
	font-size: 1.5rem;
	font-weight: var(--font-weight-regular);
	padding: var(--space-12) var(--space-12) calc(var(--space-12) - var(--type-b-leveler)) var(--space-12);
	position: fixed; /* to ignore the overflow hidden of it's parents */
	text-align: center;
	text-transform: uppercase;
	transform: translate(2px, 7px); /* make it align with center of the pointer */
	width: 260px;
	z-index: var(--z-index-hover-label);
}

@media (prefers-reduced-motion: reduce), (hover: none) {
	.columns__hover-label {
		display: none !important;
	}
}
