.social-navigation-mobile {
	display: block;
	font-size: 0;
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: center;
}

.social-navigation-mobile__item {
	display: inline-block;
	margin: var(--space-10);
	text-align: center;
	width: var(--space-40);
}

@media (min-width: 375px) {
	.social-navigation-mobile__item {
		margin: var(--space-20);
	}
}

.social-navigation-mobile__link {
	display: inline-block;
	transition: opacity 96ms linear;
}

.social-navigation-mobile__link::before {
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	content: "";
	display: block;
}

.social-navigation-mobile__link[href*="facebook.com"]::before {
	background-image: url("../images/facebook.svg");
	width: 30px;
	height: 30px;
}

.social-navigation-mobile__link[href*="instagram.com"]::before {
	background-image: url("../images/instagram.svg");
	width: 31px;
	height: 30px;
}

.social-navigation-mobile__link[href*="youtube.com"]::before {
	background-image: url("../images/youtube.svg");
	width: 41px;
	height: 29px;
}

.social-navigation-mobile__link:focus,
.social-navigation-mobile__link:hover {
	opacity: 0.5;
}
