/**
 * @license
 * MyFonts Webfont Build ID 3928635, 2020-07-23T08:48:20-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: GillSansMTPro-BoldCondensed by Monotype
 * URL: https://www.myfonts.com/fonts/mti/gill-sans/pro-bold-condensed/
 * Copyright: Font software Copyright 1990, 1991, 1998 Adobe Systems Incorporated. Typeface designs Copyright The Monotype Corporation. All rights reserved.
 *
 *
 *
 * © 2020 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
@import url("https://hello.myfonts.net/count/3bf418");

@font-face {
	font-display: block;
	font-family: "GillSansMTPro-BoldCondensed";
	font-style: normal;
	font-weight: 400;
	src: url("../fonts/GillSansMTPro-BoldCondensed/font.woff2") format("woff2");
}
