.details {
	position: relative;
	width: 100%;
}

@media (--mq-from-small) {
	.details {
		display: flex;
		flex-wrap: wrap;
	}
}

@media (--mq-from-small) {
	.details__item {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
		min-height: 364px; /* site container is 728px here, so taking half makes this a square at minimum height */
	}
}

@media (--mq-from-wide) {
	.details__item {
		min-height: 520px; /* site container is 1040px here, so taking half makes this a square at minimum height */
	}
}

/* #region Detail description item */
.details__item--description {
	background-color: var(--color-background);
	color: var(--color-foreground);
}

@media (--mq-from-small) {
	.details__item--description {
		order: 2;
	}
}
/* #endregion Detail description item */

/* #region Detail list item */
@media (--mq-from-small) {
	.details__item--details {
		order: 1;
	}
}
/* #endregion Detail list item */

/* #region Detail item inner */
.details__item__inner {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding: var(--space-20);
	justify-content: center;
	align-items: center;
	width: 100%;
}

.details__item__inner > * {
	max-width: 100%; /* force line breaks in IE11 / avoid overflow */
}

@media (--mq-from-small) {
	.details__item__inner {
		height: 100%;
	}
}

@media (--mq-from-wide) {
	.details__item__inner {
		padding: var(--space-70);
	}
}
/* #endregion Detail item inner */

/* #region Detail List */
.details__list {
	width: 100%;
}

.details__list__row {
	display: inline-flex;
	padding-top: calc(var(--space-10) + 0.125rem); /* fix vertical alignment of title font */
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
}

.details__list__row:not(:last-child) {
	border-bottom: 1px solid;
	padding-bottom: var(--space-10);
}

.details__list__key {
	display: inline-block;
	flex-basis: auto;
	flex-grow: 1;
	transform: translateY(0.125rem); /* fix vertical alignment of title font */
}

.details__list__value {
	flex-basis: 60%;
	flex-grow: 0;
	flex-shrink: 0;
	margin-left: var(--space-10);
	text-align: right;
}
/* #endregion Detail List */
