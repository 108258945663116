.site-footer {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding-top: var(--space-30);
	padding-bottom: var(--space-30);
	justify-content: center;
	align-items: center;
	position: relative;
	text-align: center;
	width: 100%;
	min-height: var(--site-footer-min-height);
}

@media (--mq-up-to-small) {
	.site-footer {
		/* Make sure scroll to top star doesn't overlap nav items too much */
		padding-right: calc(var(--scroll-to-top-star-size) - var(--space-20));
		padding-left: calc(var(--scroll-to-top-star-size) - var(--space-20));
	}
}

@media (--mq-from-small) {
	.site-footer {
		min-height: var(--site-footer-min-height-from-small);
	}
}

@media (--mq-from-wide) {
	.site-footer {
		min-height: var(--site-footer-min-height-from-wide);
	}
}

.site-footer > *:not(:last-child) {
	margin-bottom: var(--space-20);
}

@media (--mq-from-medium) {
	.site-footer__social-navigation {
		display: none;
	}
}

.site-footer__navigation {
	list-style: none;
	margin: 0;
	padding-left: 0;
	width: 100%;
}

.site-footer__navigation__item {
	display: inline-block;
	margin: var(--space-10);
}

@media (--mq-from-wide) {
	.site-footer__navigation__item {
		margin: var(--space-12);
	}
}

.site-footer__navigation__link {
	border-bottom: solid 5px var(--color-green);
	color: currentColor;
	display: inline-block;
	padding-bottom: 0.125rem;
	text-decoration: none;
	text-transform: lowercase;
	transition: border-bottom-color 96ms linear;
}

.site-footer__navigation__item.is-active .site-footer__navigation__link,
.site-footer__navigation__link:focus,
.site-footer__navigation__link:hover {
	border-bottom-color: var(--color-black);
}
