.form {
	box-sizing: border-box;
	margin-right: auto;
	margin-left: auto;
	padding-right: var(--space-20);
	padding-left: var(--space-20);
	width: 100%;
	max-width: 410px;
}

/* Reset border-radius for Safari */
.form * {
	border-radius: 0;
}

/* fieldset */
.form__group {
	border: 0;
	margin: 0;
	padding: 0;
	width: 100%;
}

.form__group:not(:last-child) {
	margin-bottom: var(--space-30);
}

@media (min-width: 1280px) {
	.form__group:not(:last-child) {
		margin-bottom: var(--space-50);
	}
}

/* legend */
.form__group__title {
	display: inline-block;
	padding: 0;
	min-width: 100%;
}

.form__group__title:not(:last-child) {
	margin-bottom: calc(var(--space-30) - 20px);
}

@media (min-width: 1280px) {
	.form__group__title:not(:last-child) {
		margin-bottom: calc(var(--space-50) - 25px);
	}
}

/**
 * ROW
 */
.form__row {
	margin-top: var(--space-10);
	margin-bottom: var(--space-10);
	width: 100%;
}

@media (min-width: 1280px) {
	.form__row {
		display: flex;
		margin-top: var(--space-20);
		margin-bottom: var(--space-20);
		justify-content: flex-end;
		align-items: flex-start;
	}
}

.form__row:first-child {
	margin-top: 0;
}

.form__row:last-child {
	margin-bottom: 0;
}

.form__row.form__row--medium-spacing {
	margin-top: var(--space-20);
	margin-bottom: var(--space-20);
}

@media (min-width: 1280px) {
	.form__row.form__row--medium-spacing {
		margin-top: var(--space-30);
		margin-bottom: var(--space-30);
	}
}

.form__row.form__row--medium-spacing:first-child {
	margin-top: 0;
}

.form__row.form__row--medium-spacing:last-child {
	margin-bottom: 0;
}

.form__row.form__row--extra-spacing {
	margin-top: var(--space-30);
	margin-bottom: var(--space-30);
}

@media (min-width: 1280px) {
	.form__row.form__row--extra-spacing {
		margin-top: var(--space-50);
		margin-bottom: var(--space-50);
	}
}

.form__row.form__row--extra-spacing:first-child {
	margin-top: 0;
}

.form__row.form__row--extra-spacing:last-child {
	margin-bottom: 0;
}

@media (min-width: 768px) {
	.form__row.form__row--flex-from-small {
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;
	}
}

@media (min-width: 1280px) {
	.form__row.form__row--full-width {
		flex-wrap: wrap;
	}
}

.form__row.form__row--align-left {
	display: flex;
	justify-content: flex-start;
}

.form__row.form__row--centered {
	display: flex;
	justify-content: center;
}

@media (max-width: 1279px) {
	.form__row.form__row--justify-up-to-wide {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
}

/**
 * LABEL
 */
.form__label-wrapper {
	display: inline-block;
}

@media (min-width: 1280px) {
	.form__label-wrapper {
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 1;
		margin-bottom: 0;
		transform: translateY(0.6875rem); /* align with field input */
	}
}

.form__row--full-width .form__label-wrapper {
	transform: none;
	width: 100%;
}

@media (min-width: 1280px) {
	.form__row--full-width .form__label-wrapper:not(:last-child) {
		margin-bottom: var(--space-20);
	}
}

@media (min-width: 768px) {
	.form__row--flex-from-small .form__label-wrapper {
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 1;
		margin-bottom: 0;
		transform: translateY(0.6875rem); /* align with field input */
	}
}

@media (max-width: 1279px) {
	.form__label-wrapper:not(:last-child) {
		margin-bottom: var(--space-10);
	}
}

.form__row--justify-up-to-wide .form__label-wrapper:not(:last-child) {
	margin-bottom: 0;
}

@media (min-width: 1280px) {
	.form__label-wrapper.form__label-wrapper--checkbox,
	.form__label-wrapper.form__label-wrapper--radio {
		transform: translateY(-0.125rem); /* align with radio input */
	}
}

@media (min-width: 768px) {
	.form__row--flex-from-small .form__label-wrapper.form__label-wrapper--checkbox,
	.form__row--flex-from-small .form__label-wrapper.form__label-wrapper--radio {
		transform: translateY(-0.125rem); /* align with radio input */
	}
}

.form__label-wrapper.form__label-wrapper--no-transform {
	transform: none;
}

.form__label {
	cursor: pointer;
}

.form__label-subtext {
	color: var(--color-orange);
	display: inline-block;
	font-size: 0.625rem;
	line-height: 1;
	text-transform: uppercase;
	width: 100%;
}

@media (min-width: 1280px) {
	.form__label-subtext {
		font-size: 0.75rem;
	}
}

/**
 * FIELD
 */
.form__field-wrapper {
	width: 100%;
}

@media (min-width: 1280px) {
	.form__field-wrapper {
		flex-grow: 0;
		flex-shrink: 0;
		margin-left: var(--space-20);
		width: 230px;
	}
}

@media (min-width: 768px) {
	.form__row--flex-from-small .form__field-wrapper {
		flex-grow: 0;
		flex-shrink: 0;
		margin-left: var(--space-20);
		width: 230px;
	}
}

.form__row--full-width .form__field-wrapper {
	margin-left: 0;
	width: 100%;
}

@media (max-width: 1279px) {
	.form__row--justify-up-to-wide .form__field-wrapper {
		flex-grow: 0;
		flex-shrink: 0;
		margin-left: var(--space-20);
		width: auto;
	}
}

/**
 * ERROR
 */
.form__error,
.form__error-label {
	color: var(--color-error);
	display: inline-block;
	font-style: italic;
	line-height: var(--space-10);
}

@media (min-width: 1280px) {
	.form__error,
	.form__error-label {
		box-sizing: border-box;
		line-height: var(--space-20);
	}
}

.form__error:empty {
	display: none;
}

.form__error-label {
	transform: translateY(0.125rem);
}

/**
 * INPUT STYLES
 */
.form__select,
.form__textarea,
.form__text-input {
	background-color: var(--color-background);
	border: 2px solid var(--color-foreground);
	box-sizing: border-box;
	outline: none;
	padding: 0.375rem var(--space-10) 0.25rem;
	width: 100%;
}

@media (min-width: 1280px) {
	.form__select,
	.form__textarea,
	.form__text-input {
		padding: 0.625rem var(--space-12) 0.5rem;
	}
}

.form__row.has-error .form__select:not(:focus),
.form__row.has-error .form__textarea:not(:focus),
.form__row.has-error .form__text-input:not(:focus) {
	border-color: var(--color-error);
}

.form__select::placeholder,
.form__textarea::placeholder,
.form__text-input::placeholder {
	color: var(--color-alpha-foreground-30);
}

.form__select:focus,
.form__textarea:focus,
.form__text-input:focus {
	box-shadow: 0 0 4px 2px var(--color-blue-light);
}

.form__select,
.form__text-input {
	height: auto;
}

.form__select {
	-webkit-appearance: none;
	background-image: url("../images/dropdown.svg");
	background-position: calc(100% - var(--space-10)) center;
	background-repeat: no-repeat;
	background-size: 0.9375rem 0.9375rem;
	padding-right: 2rem;
}

@media (min-width: 1280px) {
	.form__select {
		background-position: calc(100% - var(--space-15)) center;
		background-size: 1.125rem 1.125rem;
		padding-right: 2.5rem;
	}
}

.form__select::-ms-expand {
	display: none;
}

.form__textarea {
	line-height: inherit;
	resize: none;
	height: 7rem;
}

@media (min-width: 1280px) {
	.form__textarea {
		height: 10rem;
	}
}

/**
 * CHECKBOX + RADIO
 */

/* checkbox item */
.form__checkbox {
	width: 100%;
}

.form__checkbox:not(:last-child) {
	margin-bottom: var(--space-15);
}

/* radio group */
@media (min-width: 1280px) {
	.form__radio-group {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: calc(var(--space-15) * -1); /* reset bottom margin */
		justify-content: flex-start;
		align-items: flex-start;
	}
}

@media (min-width: 1280px) {
	.form__radio-group--column {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
}

/* radio item */
.form__radio {
	max-width: 100%;
}

@media (max-width: 1279px) {
	.form__radio {
		width: 100%;
	}
}

@media (min-width: 1280px) {
	.form__radio {
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: var(--space-30);
		margin-bottom: var(--space-15);
	}
}

@media (max-width: 1279px) {
	.form__radio:not(:last-child) {
		margin-bottom: var(--space-10);
	}
}

/* labels */
.form__checkbox__link,
.form__checkbox__label,
.form__radio__label {
	color: currentColor;
	display: block;
	line-height: var(--space-20);
	margin-left: calc(0.9375rem + var(--space-10));
}
/* checkbox width + spacing */
@media (min-width: 1280px) {
	.form__checkbox__link,
	.form__checkbox__label,
	.form__radio__label {
		margin-left: calc(1.25rem + var(--space-20)); /* checkbox width + spacing */
	}
}

@media (max-width: 1279px) {
	.form__row--justify-up-to-wide .form__checkbox__link:empty,
	.form__row--justify-up-to-wide .form__checkbox__label:empty,
	.form__row--justify-up-to-wide .form__radio__label:empty {
		margin-left: 0; /* no extra margin on justify */
		transform: translateX(var(--space-10)); /* no extra margin on justify */
	}
}

.form__checkbox__label,
.form__radio__label {
	cursor: pointer;
	position: relative;
}

.form__checkbox__label::after,
.form__checkbox__label::before,
.form__radio__label::after,
.form__radio__label::before {
	content: "";
}

.form__checkbox__label::before,
.form__radio__label::before {
	background-color: var(--color-background);
	border: 2px solid var(--color-foreground);
	box-sizing: border-box;
	position: absolute;
	top: 0.0625rem;
	left: calc((0.9375rem + var(--space-10)) * -1); /* checkbox width + spacing */
	width: 0.9375rem;
	height: 0.9375rem;
}

@media (min-width: 1280px) {
	.form__checkbox__label::before,
	.form__radio__label::before {
		top: -0.0625rem;
		left: calc((1.25rem + var(--space-20)) * -1); /* checkbox width + spacing */
		width: 1.25rem;
		height: 1.25rem;
	}
}

/* pseudo checkbox */
.form__checkbox__input:checked + .form__checkbox__label::after {
	background-image: url("../images/checkmark.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: 80%;
	color: var(--color-foreground);
	content: "";
	position: absolute;
	top: 0.1875rem;
	left: calc((0.9375rem + var(--space-10) - 0.125rem) * -1); /* checkbox width + spacing - offset */
	width: 0.6875rem;
	height: 0.6875rem;
}

@media (min-width: 1280px) {
	.form__checkbox__input:checked + .form__checkbox__label::after {
		background-size: 75%;
		font-size: 0.9375rem;
		line-height: 1.25rem;
		top: 0.0625rem;
		left: calc((1.25rem + var(--space-20) - 0.125rem) * -1); /* checkbox width + spacing - offset */
		width: 1rem;
		height: 1rem;
	}
}

.form__checkbox__input:focus + .form__checkbox__label::before {
	box-shadow: 0 0 4px 2px var(--color-blue-light);
}

.form__checkbox__input:focus:not(:focus-visible) + .form__checkbox__label::before {
	/* no visible focus for mouse users */
	box-shadow: none;
}

/* pseudo radio */
.form__radio__label::after,
.form__radio__label::before {
	border-radius: 100%;
	box-sizing: border-box;
}

.form__radio__label::after {
	background-color: var(--color-foreground);
	border: 2px solid var(--color-foreground);
	box-shadow: inset 0 0 0 0.125rem var(--color-background);
	display: none;
	position: absolute;
	top: 0.0625rem;
	left: calc((0.9375rem + var(--space-10)) * -1);
	width: 0.9375rem;
	height: 0.9375rem;
}

@media (min-width: 1280px) {
	.form__radio__label::after {
		box-shadow: inset 0 0 0 0.1875rem var(--color-background);
		top: -0.0625rem;
		left: calc((1.25rem + var(--space-20)) * -1);
		width: 1.25rem;
		height: 1.25rem;
	}
}

.form__radio__input:checked + .form__radio__label::after {
	display: block;
}

.form__radio__input:focus + .form__radio__label::before {
	box-shadow: 0 0 4px 2px var(--color-blue-light);
}

.form__radio__input:focus:not(:focus-visible) + .form__radio__label::before {
	/* no visible focus for mouse users */
	box-shadow: none;
}

/* File Input */
.form__file-input {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

@media (min-width: 768px) {
	.form__file-input {
		flex-direction: row;
	}
}

.form__file-input__input:focus + .form__file-input__button,
.form__file-input:hover .form__file-input__button {
	animation: SHAKE 112ms ease-in-out infinite;
}

@media (prefers-reduced-motion) {
	.form__file-input__input:focus + .form__file-input__button,
	.form__file-input:hover .form__file-input__button {
		animation: none;
		background-color: var(--color-red);
		color: var(--color-white);
	}
}

.form__file-input__input:focus:not(:focus-visible) + .form__file-input__button {
	/* no visible focus for mouse users */
	animation: none;
}

.form__file-input__input:focus-visible + .form__file-input__button {
	/* visible focus for keyboard users */
	animation: SHAKE 112ms ease-in-out infinite;
}

@media (prefers-reduced-motion) {
	.form__file-input__input:focus-visible + .form__file-input__button {
		animation: none;
		background-color: var(--color-red);
		color: var(--color-white);
	}
}

.form__file-input__input:focus + .form__file-input__button {
	/* fallback */
	box-shadow: 0 0 0.625rem 0.25rem var(--color-red);
}

.form__file-input__input:focus:not(:focus-visible) + .form__file-input__button {
	/* no visible focus for mouse users */
	box-shadow: none;
}

.form__file-input__input:focus + .form__file-input__button.button--theme-orange,
.form__file-input__input:focus + .form__file-input__button.button:not([class*="button--theme-"]) {
	/* fallback */
	box-shadow: 0 0 0.75rem 0.25rem var(--color-alpha-black-75);
}

.form__file-input__input:focus:not(:focus-visible) + .form__file-input__button.button--theme-orange,
.form__file-input__input:focus:not(:focus-visible) + .form__file-input__button.button:not([class*="button--theme-"]) {
	/* no visible focus for mouse users */
	box-shadow: none;
}

.theme-orange .form__file-input__input:focus + .form__file-input__button,
.theme-red .form__file-input__input:focus + .form__file-input__button {
	/* fallback */
	box-shadow: 0 0 0.75rem 0.25rem var(--color-white);
}

.theme-orange .form__file-input__input:focus:not(:focus-visible) + .form__file-input__button,
.theme-red .form__file-input__input:focus:not(:focus-visible) + .form__file-input__button {
	/* no visible focus for mouse users */
	box-shadow: none;
}

.form__file-input__label {
	display: flex;
	overflow: hidden;
	justify-content: center;
}

@media (max-width: 767px) {
	.form__file-input__label {
		margin-top: var(--space-20);
		width: 100%;
	}
}

@media (min-width: 768px) {
	.form__file-input__label {
		flex-basis: calc(100% - 100px - 20px);
		flex-grow: 0;
		flex-shrink: 1;
		justify-content: flex-end;
	}
}

@media (min-width: 1280px) {
	.form__file-input__label {
		flex-basis: calc(100% - 114px - 30px);
	}
}

.form__file-input__file-start {
	flex-grow: 0;
	flex-shrink: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.form__file-input__file-end {
	flex-grow: 0;
	flex-shrink: 0;
}
