.dialog {
	display: none;
	opacity: 0;
	pointer-events: none;
}

.dialog[data-state="opening"],
.dialog[data-state="open"],
.dialog[data-state="closing"],
.l-styleguide .dialog {
	display: block;
	opacity: 1;
	pointer-events: auto;
}

.dialog:focus {
	outline: none;
}

.dialog__content {
	display: block;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: calc(100% - (var(--space-20) * 2));
}

@media (--mq-from-small) {
	.dialog__content {
		width: auto;
		max-width: 600px;
	}
}

@media (--mq-from-wide) {
	.dialog__content {
		max-width: 800px;
	}
}

#privacy-dialog .dialog__content {
	width: 288px;
	z-index: var(--z-index-privacy-d-fg);
}

@media (--mq-from-small) {
	#privacy-dialog .dialog__content {
		top: auto;
		right: var(--space-40);
		bottom: 40px;
		left: auto;
		transform: translateX(0) translateY(0);
		width: 302px;
	}
}

.l-styleguide .dialog__content {
	margin-right: var(--space-20);
	margin-left: auto;
	position: relative;
	top: auto;
	right: auto;
	bottom: auto;
	left: auto;
	transform: none;
}

.dialog__inner {
	background-color: var(--color-background);
	display: block;
	overflow-x: hidden;
	overflow-y: auto;
	position: relative;
	width: 100%;
	max-height: 35vh;
	z-index: 2;
}

@media (--mq-from-small) and (min-height: 580px) {
	.dialog__inner {
		max-height: 300px;
	}
}

@media (--mq-from-small) and (min-height: 680px) {
	.dialog__inner {
		max-height: 400px;
	}
}

@media (--mq-from-wide) and (min-height: 780px) {
	.dialog__inner {
		max-height: 500px;
	}
}

@media (--mq-from-wide) and (min-height: 880px) {
	.dialog__inner {
		max-height: 600px;
	}
}
