.superett-banner-item {
	box-sizing: border-box;
	display: block;
	overflow: hidden;
	padding: 12.5rem var(--space-20) var(--space-15);
	position: relative;
	text-decoration: none;
	text-transform: uppercase;
	width: 100%;
	height: 100%;
}

@media (min-width: 1280px) {
	.superett-banner-item {
		padding: 15.625rem var(--space-40) var(--space-50) var(--space-50);
	}
}

.superett-banner .superett-banner-item {
	background-color: transparent;
}

.superett-banner-item__thumbnail {
	border: 10px solid var(--color-white);
	box-shadow: 0.375rem 0.375rem 0.25rem 0 var(--color-alpha-black-35);
	display: block;
	position: absolute;
	top: -0.75rem;
	left: 2.25rem;
	transform: rotate(-10deg);
	transition: transform 368ms cubic-bezier(0.65, 0, 0.05, 1);
	width: 9.375rem;
	height: 9.375rem;
}

@media (min-width: 1280px) {
	.superett-banner-item__thumbnail {
		width: 12.5rem;
		height: 12.5rem;
	}
}

@media (min-width: 1280px) {
	.superett-banner-item__thumbnail {
		left: 4.25rem;
	}
}

.superett-banner-item:focus .superett-banner-item__thumbnail,
.superett-banner-item:hover .superett-banner-item__thumbnail {
	transform: scale(1.075) rotate(-8deg);
}

.superett-banner-item[data-variant="2"] .superett-banner-item__thumbnail {
	top: -1rem;
	left: 3.25rem;
	transform: rotate(11deg);
}

@media (min-width: 1280px) {
	.superett-banner-item[data-variant="2"] .superett-banner-item__thumbnail {
		top: -1.5rem;
		left: 5.125rem;
	}
}

.superett-banner-item[data-variant="2"]:focus .superett-banner-item__thumbnail,
.superett-banner-item[data-variant="2"]:hover .superett-banner-item__thumbnail {
	transform: scale(1.075) rotate(9deg);
}

.superett-banner-item[data-variant="3"] .superett-banner-item__thumbnail {
	top: -0.875rem;
	left: 2.875rem;
	transform: rotate(-3deg);
}

@media (min-width: 1280px) {
	.superett-banner-item[data-variant="3"] .superett-banner-item__thumbnail {
		left: 4rem;
	}
}

.superett-banner-item[data-variant="3"]:focus .superett-banner-item__thumbnail,
.superett-banner-item[data-variant="3"]:hover .superett-banner-item__thumbnail {
	transform: scale(1.075);
}

.superett-banner-item__title {
	display: inline-block;
}

@media (max-width: 767px) {
	.superett-banner-item__title {
		display: inline-block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: calc(100vw - (var(--space-20) * 4)); /* site width - wrapper padding - inner padding */
	}
}

.superett-banner-item__title:not(:last-child) {
	margin-bottom: 0.25rem;
}

@media (min-width: 768px) {
	.superett-banner-item__title:not(:last-child) {
		margin-bottom: 0.125rem;
	}
}

@media (min-width: 1280px) {
	.superett-banner-item__title:not(:last-child) {
		margin-bottom: 0.625rem;
	}
}

.superett-banner-item:focus .superett-banner-item__title,
.superett-banner-item:hover .superett-banner-item__title {
	text-decoration: underline;
}

@media (min-width: 1280px) {
	.superett-banner-item__description {
		padding-right: var(--space-10);
	}
}

@supports (-webkit-line-clamp: 3) {
	.superett-banner-item__description {
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		display: block;
		display: -webkit-box;
		overflow: hidden;
	}
}
