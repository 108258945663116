.contact-rows {
}

@media (min-width: 768px) {
	.contact-rows__row {
		display: flex;
	}
}

@media (min-width: 768px) {
	.contact-rows__content-container,
	.contact-rows__flag-container {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.contact-rows__row__inner {
	box-sizing: border-box;
	padding: var(--space-12) var(--space-20);
}

@media (min-width: 768px) {
	.contact-rows__row__inner {
		display: flex;
		padding: var(--space-50);
		min-height: 17.5rem;
	}
}

@media (min-width: 1280px) {
	.contact-rows__row__inner {
		min-height: 25rem;
	}
}

.contact-rows__flag-container {
	font-size: 1.875rem;
	text-align: center;
}

@media (min-width: 1280px) {
	.contact-rows__flag-container {
		font-size: 3.125rem;
	}
}

@media (min-width: 768px) {
	.contact-rows__row:nth-child(even) .contact-rows__content-container {
		order: -1;
	}
}

@media (min-width: 768px) {
	.contact-rows__flag,
	.contact-rows__content {
		margin: auto;
	}
}

.contact-rows__flag {
	display: inline-block;
	transform: translateY(0.375rem);
}

/* visually align vertically */
@media (min-width: 1280px) {
	.contact-rows__flag {
		transform: translateY(0.4375rem); /* visually align vertically */
	}
}

@media (min-width: 768px) {
	.contact-rows__content {
		max-width: 15.4375rem; /* specific value from design */
	}
}
