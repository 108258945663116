.social-navigation {
	background-image: url("../images/social-stamp.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	box-sizing: border-box;
	display: block;
	font-size: 0;
	list-style: none;
	margin: 0;
	padding: 40px 32px;
	text-align: center;
	width: 132px;
	height: 132px;
}

.social-navigation__item {
	display: inline-block;
}

.social-navigation__item:nth-of-type(even) {
	margin-left: var(--space-15);
}

@media (--mq-from-wide) {
	.social-navigation__item:nth-of-type(even) {
		margin-left: var(--space-20);
	}
}

.social-navigation__item:nth-of-type(n + 3) {
	margin-top: var(--space-12);
}

@media (--mq-from-wide) {
	.social-navigation__item:nth-of-type(n + 3) {
		margin-top: var(--space-15);
	}
}

.social-navigation__link {
	display: inline-block;
	transition: opacity 96ms linear;
}

.social-navigation__link::before {
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	content: "";
	display: block;
}

.social-navigation__link[href*="facebook.com"]::before {
	background-image: url("../images/facebook.svg");
	width: 20px;
	height: 20px;
}

@media (--mq-from-wide) {
	.social-navigation__link[href*="facebook.com"]::before {
		width: 24px;
		height: 24px;
	}
}

.social-navigation__link[href*="instagram.com"]::before {
	background-image: url("../images/instagram.svg");
	width: 20px;
	height: 20px;
}

@media (--mq-from-wide) {
	.social-navigation__link[href*="instagram.com"]::before {
		width: 24px;
		height: 24px;
	}
}

.social-navigation__link[href*="youtube.com"]::before {
	background-image: url("../images/youtube.svg");
	width: 26px;
	height: 18px;
}

@media (--mq-from-wide) {
	.social-navigation__link[href*="youtube.com"]::before {
		width: 32px;
		height: 22px;
	}
}

.social-navigation__link:focus,
.social-navigation__link:hover {
	opacity: 0.5;
}
