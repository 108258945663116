.button {
	-webkit-appearance: none;
	background: none;
	background-color: var(--color-red);
	border: 0;
	border-radius: 0;
	box-shadow: none;
	color: var(--color-white);
	cursor: pointer;
	display: inline-block;
	font-family: var(--font-title);
	font-size: 1.125rem;
	font-weight: var(--font-weight-regular);
	line-height: 1;
	outline: none;
	padding: var(--space-12) var(--space-12) calc(var(--space-12) - var(--type-b-leveler)) var(--space-12);
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	transform-origin: center center;
}

@media (--mq-from-wide) {
	.button {
		font-size: 1.75rem;
		padding: var(--space-15) var(--space-15) calc(var(--space-15) - var(--type-b-leveler-from-wide)) var(--space-15);
	}
}

/* SIZE VARIANTS */

.button.button--small {
	font-size: 0.75rem;
	padding: var(--space-10) var(--space-30) calc(var(--space-10) - var(--type-f-leveler)) var(--space-30);
}

@media (--mq-from-wide) {
	.button.button--small {
		font-size: 1rem;
		padding-top: var(--space-15);
		padding-bottom: calc(var(--space-15) - var(--type-f-leveler-from-wide));
	}
}

/* COLOR VARIANTS */

.button.button--theme-black {
	background-color: var(--color-black);
}

.button.button--theme-blue-dark {
	background-color: var(--color-blue-dark);
}

.button.button--theme-blue-light {
	background-color: var(--color-blue-light);
}

.button.button--theme-green {
	background-color: var(--color-green);
}

.button.button--theme-grey {
	background-color: var(--color-grey);
}

.button.button--theme-grey-light {
	background-color: var(--color-grey-light);
}

.button.button--theme-orange {
	background-color: var(--color-orange);
}

.button.button--theme-pink {
	background-color: var(--color-pink);
}

.button.button--theme-white {
	background-color: var(--color-white);
}

.button.button--theme-yellow {
	background-color: var(--color-yellow);
}

.button.button--theme-black,
.button.button--theme-blue-dark {
	color: var(--color-white);
}

.button.button--theme-blue-light,
.button.button--theme-green,
.button.button--theme-grey,
.button.button--theme-grey-light,
.button.button--theme-orange,
.button.button--theme-pink,
.button.button--theme-white,
.button.button--theme-yellow {
	color: var(--color-black);
}

/* FOCUS + HOVER */

.button:focus,
.button:hover {
	animation: SHAKE 112ms ease-in-out infinite;
}

@media (prefers-reduced-motion) {
	.button:focus,
	.button:hover {
		animation: none;
		background-color: var(--color-red);
		color: var(--color-white);
	}
}

.button:focus:not(:focus-visible) {
	/* no visible focus for mouse users */
	animation: none;
}

@media (prefers-reduced-motion) {
	.theme-red .button:focus,
	.theme-red .button:hover,
	.button.button:not([class*="button--theme-"]):focus,
	.button.button:not([class*="button--theme-"]):hover {
		background-color: var(--color-black);
		color: var(--color-white);
	}
}

/* no visible focus for mouse users */
@media (prefers-reduced-motion) {
	.button.button:not([class*="button--theme-"]):focus:not(:focus-visible) {
		background-color: var(--color-red);
		color: var(--color-white);
	}
}

.button:focus {
	box-shadow: 0 0 0.625rem 0.25rem var(--color-red);
}

.button:focus:not(:focus-visible) {
	/* no visible focus for mouse users */
	box-shadow: none;
}

.button.button--theme-orange:focus,
.button.button:not([class*="button--theme-"]):focus {
	box-shadow: 0 0 0.75rem 0.25rem var(--color-alpha-black-75);
}

.button.button--theme-orange:focus:not(:focus-visible),
.button.button:not([class*="button--theme-"]):focus:not(:focus-visible) {
	/* no visible focus for mouse users */
	box-shadow: none;
}

.theme-orange .button:focus,
.theme-red .button:focus {
	box-shadow: 0 0 0.75rem 0.25rem var(--color-white);
}

.theme-orange .button:focus:not(:focus-visible),
.theme-red .button:focus:not(:focus-visible) {
	/* no visible focus for mouse users */
	box-shadow: none;
}

/* DISABLED */

.button[disabled],
.button:disabled {
	background-color: var(--color-grey);
	color: var(--color-alpha-black-30);
}

.theme-grey .button[disabled],
.theme-grey .button:disabled,
.theme-grey-light .button[disabled],
.theme-grey-light .button:disabled {
	background-color: var(--color-alpha-black-30);
	color: var(--color-grey);
}
