* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: var(--font-sans);
	font-size: 1rem;
	font-weight: var(--font-weight-regular);
}

@media (min-width: 1280px) {
	body {
		font-size: 1.125rem;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: var(--space-20);
	margin-bottom: var(--space-20);
}

@media (min-width: 768px) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: var(--space-30);
		margin-bottom: var(--space-30);
	}
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
	margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
	margin-bottom: 0;
}

p {
	margin-top: 0;
	margin-bottom: var(--space-20);
}

p:last-child {
	margin-bottom: 0;
}

h1,
blockquote > p:first-child,
.type-a {
	font-family: var(--font-title);
	font-size: 1.875rem;
	font-weight: var(--font-weight-regular);
	line-height: 1;
	text-transform: uppercase;
}

@media (min-width: 1280px) {
	h1,
	blockquote > p:first-child,
	.type-a {
		font-size: 2.5rem;
	}
}

h1:not(:last-child),
h1.type-a:not(:last-child),
h2.type-a:not(:last-child),
h3.type-a:not(:last-child),
h4.type-a:not(:last-child),
h5.type-a:not(:last-child),
h6.type-a:not(:last-child) {
	margin-bottom: calc(var(--space-20) - var(--type-a-leveler));
}

@media (min-width: 1280px) {
	h1:not(:last-child),
	h1.type-a:not(:last-child),
	h2.type-a:not(:last-child),
	h3.type-a:not(:last-child),
	h4.type-a:not(:last-child),
	h5.type-a:not(:last-child),
	h6.type-a:not(:last-child) {
		margin-bottom: calc(var(--space-30) - var(--type-a-leveler-from-wide));
	}
}

h2,
.type-b {
	font-family: var(--font-title);
	font-size: 1.5rem;
	font-weight: var(--font-weight-regular);
	line-height: 1.0417;
	text-transform: uppercase;
}

@media (min-width: 1280px) {
	h2,
	.type-b {
		font-size: 1.75rem;
		line-height: 1;
	}
}

h2:not(:last-child),
h1.type-b:not(:last-child),
h2.type-b:not(:last-child),
h3.type-b:not(:last-child),
h4.type-b:not(:last-child),
h5.type-b:not(:last-child),
h6.type-b:not(:last-child) {
	margin-bottom: calc(var(--space-20) - var(--type-b-leveler));
}

@media (min-width: 1280px) {
	h2:not(:last-child),
	h1.type-b:not(:last-child),
	h2.type-b:not(:last-child),
	h3.type-b:not(:last-child),
	h4.type-b:not(:last-child),
	h5.type-b:not(:last-child),
	h6.type-b:not(:last-child) {
		margin-bottom: calc(var(--space-30) - var(--type-b-leveler-from-wide));
	}
}

h3,
.type-c {
	font-family: var(--font-title);
	font-size: 1rem;
	font-weight: var(--font-weight-regular);
	line-height: 1;
	text-transform: uppercase;
}

@media (min-width: 1280px) {
	h3,
	.type-c {
		font-size: 1.375rem;
	}
}

h3:not(:last-child),
h1.type-c:not(:last-child),
h2.type-c:not(:last-child),
h3.type-c:not(:last-child),
h4.type-c:not(:last-child),
h5.type-c:not(:last-child),
h6.type-c:not(:last-child) {
	margin-bottom: calc(var(--space-20) - var(--type-c-leveler));
}

@media (min-width: 1280px) {
	h3:not(:last-child),
	h1.type-c:not(:last-child),
	h2.type-c:not(:last-child),
	h3.type-c:not(:last-child),
	h4.type-c:not(:last-child),
	h5.type-c:not(:last-child),
	h6.type-c:not(:last-child) {
		margin-bottom: calc(var(--space-30) - var(--type-c-leveler-from-wide));
	}
}

h4,
.type-d {
	font-family: var(--font-title);
	font-size: 1.125rem;
	font-weight: var(--font-weight-regular);
	line-height: 1.11;
	text-transform: uppercase;
}

@media (min-width: 1280px) {
	h4,
	.type-d {
		font-size: 1.25rem;
		line-height: 1;
	}
}

h4:not(:last-child),
h1.type-d:not(:last-child),
h2.type-d:not(:last-child),
h3.type-d:not(:last-child),
h4.type-d:not(:last-child),
h5.type-d:not(:last-child),
h6.type-d:not(:last-child) {
	margin-bottom: calc(var(--space-20) - var(--type-d-leveler));
}

@media (min-width: 1280px) {
	h4:not(:last-child),
	h1.type-d:not(:last-child),
	h2.type-d:not(:last-child),
	h3.type-d:not(:last-child),
	h4.type-d:not(:last-child),
	h5.type-d:not(:last-child),
	h6.type-d:not(:last-child) {
		margin-bottom: calc(var(--space-30) - var(--type-d-leveler-from-wide));
	}
}

.type-e {
	font-family: var(--font-sans);
	font-size: 1.125rem;
	line-height: 1.22;
}

@media (min-width: 1280px) {
	.type-e {
		font-size: 1.25rem;
	}
}

h5,
h6,
th,
.type-f,
.type-g {
	font-family: var(--font-title);
	font-size: 0.75rem;
	font-weight: var(--font-weight-regular);
	letter-spacing: 0.0469rem;
	line-height: 1.83;
	text-transform: uppercase;
}

@media (min-width: 1280px) {
	h5,
	h6,
	th,
	.type-f,
	.type-g {
		font-size: 1rem;
		letter-spacing: 0.0625rem;
		line-height: 1.22;
	}
}

h5:not(:last-child),
h1.type-f:not(:last-child),
h2.type-f:not(:last-child),
h3.type-f:not(:last-child),
h4.type-f:not(:last-child),
h5.type-f:not(:last-child),
h6.type-f:not(:last-child) {
	margin-bottom: calc(var(--space-20) - var(--type-f-leveler));
}

@media (min-width: 1280px) {
	h5:not(:last-child),
	h1.type-f:not(:last-child),
	h2.type-f:not(:last-child),
	h3.type-f:not(:last-child),
	h4.type-f:not(:last-child),
	h5.type-f:not(:last-child),
	h6.type-f:not(:last-child) {
		margin-bottom: calc(var(--space-30) - var(--type-f-leveler-from-wide));
	}
}

h6,
.type-g {
	font-family: var(--font-title);
	font-weight: var(--font-weight-regular);
	opacity: 0.35;
}

h6:not(:last-child),
h1.type-g:not(:last-child),
h2.type-g:not(:last-child),
h3.type-g:not(:last-child),
h4.type-g:not(:last-child),
h5.type-g:not(:last-child),
h6.type-g:not(:last-child) {
	margin-bottom: calc(var(--space-20) - var(--type-g-leveler));
}

@media (min-width: 1280px) {
	h6:not(:last-child),
	h1.type-g:not(:last-child),
	h2.type-g:not(:last-child),
	h3.type-g:not(:last-child),
	h4.type-g:not(:last-child),
	h5.type-g:not(:last-child),
	h6.type-g:not(:last-child) {
		margin-bottom: calc(var(--space-30) - var(--type-g-leveler-from-wide));
	}
}

.type-h {
	/* body */
	font-family: var(--font-sans);
	font-size: 1rem;
	font-weight: var(--font-weight-regular);
}

@media (min-width: 1280px) {
	.type-h {
		font-size: 1.125rem;
	}
}

i,
em {
	font-style: italic;
}

cite {
	font-style: normal;
}

b,
strong {
	font-weight: var(--font-weight-bold);
}

blockquote {
	margin: var(--space-30);
}

@media (min-width: 1280px) {
	blockquote {
		margin: var(--space-50);
	}
}

blockquote p:first-child {
	position: relative;
}

blockquote p:first-child::before {
	content: "“";
	position: absolute;
	top: 0;
	left: -1rem;
}

@media (min-width: 1280px) {
	blockquote p:first-child::before {
		left: -1.25rem;
	}
}

blockquote p:first-child::after {
	content: "”";
}

blockquote p:not(:last-child) {
	margin-bottom: 0;
}

@media (min-width: 1280px) {
	blockquote p:not(:last-child) {
		margin-bottom: var(--space-20);
	}
}

blockquote cite::before,
blockquote p:not(:first-child)::before {
	content: "— ";
}
