.beer-overview-header {
	background-color: var(--color-yellow);
	font-family: var(--font-sans);
	font-size: 1rem;
	font-weight: var(--font-weight-bold);
	overflow: visible;
	position: relative;
	text-transform: uppercase;
	width: 100%;
}

@media (min-width: 1280px) {
	.beer-overview-header {
		font-size: 1.25rem;
	}
}

.beer-overview-header__list {
	background-color: inherit;
	list-style: none;
	margin: 0;
	padding-left: 0;
}

@media (min-width: 768px) {
	.beer-overview-header__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
}

.beer-overview-header__list__item {
	background-color: inherit;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 767px) {
	.beer-overview-header__list__item {
		display: none;
	}
}

@media (min-width: 768px) {
	.beer-overview-header__list__item {
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 0;
		max-width: 12.5rem;
	}
}

@media (min-width: 1280px) {
	.beer-overview-header__list__item {
		max-width: 15.625rem;
	}
}

.beer-overview-header__list__item.beer-overview-header__list__item--current {
	position: relative;
}

@media (max-width: 767px) {
	.beer-overview-header__list__item.beer-overview-header__list__item--current {
		display: flex;
	}
}

.beer-overview-header__list__item.beer-overview-header__list__item--current::after {
	background-color: inherit;
	content: "";
	pointer-events: none;
	position: absolute;
	bottom: -0.625rem;
	left: 50%;
	transform: rotate(45deg) translateX(-50%);
	user-select: none;
	width: 0.875rem;
	height: 0.875rem;
	z-index: 1;
}

.beer-overview-header__list__link {
	color: var(--color-white);
	display: inline-block;
	padding: var(--space-10) var(--space-20);
	position: relative;
	text-align: center;
	text-decoration: none;
	transition: color 96ms linear;
	z-index: 2;
}

@media (min-width: 768px) {
	.beer-overview-header__list__link {
		padding-top: var(--space-15);
		padding-bottom: var(--space-15);
	}
}

@media (min-width: 1280px) {
	.beer-overview-header__list__link {
		padding-top: var(--space-20);
		padding-bottom: var(--space-20);
	}
}

.beer-overview-header__list__item--current .beer-overview-header__list__link,
.beer-overview-header__list__link:focus,
.beer-overview-header__list__link:hover {
	color: var(--color-red);
}

.beer-overview-header__list__label {
	display: inline-block;
	transform: translateY(0.125rem);
}
