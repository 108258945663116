.emoji-call-to-action {
	overflow: hidden;
	width: 100%;
}

.emoji-call-to-action__link {
	color: currentColor;
	display: block;
	position: relative;
	text-decoration: none;
	width: 100%;
}

.emoji-call-to-action__hover-right {
	position: absolute;
	top: 0;
	right: 0;
	width: 50%;
	height: 100%;
	z-index: 2;
}

@media (--mq-up-to-small) {
	.emoji-call-to-action__hover-right {
		display: none;
	}
}

.emoji-call-to-action__inner {
	display: flex;
	padding: var(--space-15) var(--space-40);
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 1;
}

@media (--mq-up-to-small) {
	.emoji-call-to-action__inner {
		flex-direction: column;
	}
}

@media (--mq-from-small) {
	.emoji-call-to-action__inner {
		padding: var(--space-15) var(--space-20);
	}
}

@media (--mq-from-wide) {
	.emoji-call-to-action__inner {
		padding: var(--space-40) var(--space-50);
	}
}

.emoji-call-to-action__emoji,
.emoji-call-to-action__text-1,
.emoji-call-to-action__text-2 {
	margin-top: 0;
	margin-bottom: 0;
}

.emoji-call-to-action__text-1,
.emoji-call-to-action__text-2 {
	display: inline-block;
	flex-basis: auto;
	text-transform: uppercase;
	transform: translateY(0.125rem);
}

/* visually align vertically */
@media (--mq-up-to-small) {
	.emoji-call-to-action__text-1,
	.emoji-call-to-action__text-2 {
		text-align: center;
	}
}

@media (--mq-from-small) {
	.emoji-call-to-action__text-1,
	.emoji-call-to-action__text-2 {
		flex-basis: calc((100% - 20.625rem - (var(--space-20) * 2)) / 2); /* half available width - emoji width - margins */
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (--mq-from-wide) {
	.emoji-call-to-action__text-1,
	.emoji-call-to-action__text-2 {
		flex-basis: calc((100% - 27.5rem - (var(--space-20) * 2)) / 2); /* half available width - emoji width - margins */
	}
}

@media (--mq-from-small) {
	.emoji-call-to-action__text-2 {
		text-align: right;
	}
}

.emoji-call-to-action__emoji {
	flex-grow: 0;
	flex-shrink: 0;
	font-size: 0;
	text-align: center;
	user-select: none;
}

@media (--mq-up-to-small) {
	.emoji-call-to-action__emoji {
		margin-top: 0.4375rem;
		margin-bottom: 0.4375rem;
		white-space: nowrap;
		width: auto;
		width: max-content;
	}
}

@media (--mq-from-small) {
	.emoji-call-to-action__emoji {
		margin-right: var(--space-20);
		margin-left: var(--space-20);
		width: 20.625rem;
	}
}

@media (--mq-from-wide) {
	.emoji-call-to-action__emoji {
		width: 27.5rem;
	}
}

.emoji-call-to-action__link:focus .emoji-call-to-action__emoji,
.emoji-call-to-action__link:hover .emoji-call-to-action__emoji {
	animation: SHAKE 112ms ease-in-out infinite;
}

.emoji-call-to-action__emoji__item {
	display: inline-block;
	font-size: 1.875rem;
	transform: translateY(0.375rem);
}

/* visually align vertically */
@media (--mq-from-wide) {
	.emoji-call-to-action__emoji__item {
		font-size: 2.5rem;
	}
}

.emoji-call-to-action__emoji__item::after {
	content: "👈";
}

@media (hover: hover) {
	.emoji-call-to-action__hover-right:focus ~ .emoji-call-to-action__inner .emoji-call-to-action__emoji__item::after,
	.emoji-call-to-action__hover-right:hover ~ .emoji-call-to-action__inner .emoji-call-to-action__emoji__item::after {
		content: "👉";
	}
}
