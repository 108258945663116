.beer-overview-inline {
	background-color: var(--color-black);
	font-family: var(--font-sans);
	font-size: 1.25rem;
	font-weight: var(--font-weight-bold);
	text-transform: uppercase;
	width: 100%;
}

@media (--mq-from-small) {
	.beer-overview-inline {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 8.75rem;
	}
}

@media (--mq-from-wide) {
	.beer-overview-inline {
		justify-content: center;
		min-height: 6.25rem;
	}
}

.beer-overview-inline.theme-text-black {
	background-color: var(--color-white);
}

.beer-overview-inline.theme-text-blue-dark {
	background-color: var(--color-blue-light);
}

.beer-overview-inline__list {
	background-color: inherit;
	list-style: none;
	margin: 0;
	padding-top: var(--space-10);
	padding-bottom: var(--space-10);
	padding-left: 0;
}

@media (--mq-from-small) {
	.beer-overview-inline__list {
		display: flex;
		flex-wrap: wrap;
		padding: var(--space-20) var(--space-50);
		justify-content: center;
	}
}

@media (--mq-from-wide) {
	.beer-overview-inline__list {
		padding-right: 0;
		padding-left: 0;
		width: 100%;
	}
}

.beer-overview-inline__list__item {
	text-align: center;
}

@media (--mq-up-to-small) {
	.beer-overview-inline__list__item {
		width: 100%;
	}
}

@media (--mq-from-wide) {
	.beer-overview-inline__list__item {
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 0;
		max-width: 15.625rem;
	}
}

.beer-overview-inline__list__link {
	color: var(--color-white);
	display: inline-block;
	padding: var(--space-10) var(--space-20);
	text-decoration: none;
	transition: color 96ms linear;
}

@media (--mq-from-small) {
	.beer-overview-inline__list__link {
		padding-right: var(--space-25);
		padding-left: var(--space-25);
	}
}

.theme-text-black .beer-overview-inline__list__link {
	color: var(--color-black);
}

.beer-overview-inline__list__item--current .beer-overview-inline__list__link,
.beer-overview-inline__list__link:focus,
.beer-overview-inline__list__link:hover {
	color: inherit;
}

.beer-overview-inline__list__label {
	display: inline-block;
	transform: translateY(0.125rem);
}
