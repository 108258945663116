.age-gate-trigger {
	background-color: var(--color-blue-light);
	border: 0;
	border-radius: 1rem;
	box-shadow: none;
	color: var(--color-blue-light); /* this is so the SVG can nicely follow the color */
	cursor: pointer;
	padding: 0.25rem 0.625rem;
	position: relative;
	transition:
		background-color 96ms linear,
		color 96ms linear;
	min-height: 1.625rem;
}

@media (--mq-from-wide) {
	.age-gate-trigger {
		padding: 0.4375rem 0.9375rem;
		min-height: 2.125rem;
	}
}

.age-gate-trigger:focus,
.age-gate-trigger:hover {
	background-color: var(--color-red);
	color: var(--color-red);
}

.age-gate-trigger__label {
	background-color: inherit;
	color: var(--color-white);
	position: relative;
	text-transform: lowercase;
	z-index: 2;
}

/*
	 * This element can cause the entire header to jitter.
	 * - using loading fallbacks
	 * - using a stretcher element to set a fixed width and height
	 */

.age-gate-trigger__label .age-gate-trigger__label__stretcher,
html:not(.wf-proximanovacondensed-n4-active) .age-gate-trigger__label .age-gate-trigger__label__content {
	font-family: sans-serif !important;
	font-weight: 100;
	letter-spacing: -1px;
	line-height: 1;
}

.age-gate-trigger__label .age-gate-trigger__label__stretcher {
	opacity: 0;
	visibility: hidden;
}

.age-gate-trigger__label .age-gate-trigger__label__content {
	position: absolute;
	text-align: center;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

html:not(.wf-proximanovacondensed-n4-active) .age-gate-trigger__label .age-gate-trigger__label__content {
	/* Prevent jump during font loading */
	padding-bottom: 2px;
}

.age-gate-trigger__pointer {
	position: absolute;
	right: -0.25rem;
	bottom: 0;
	width: 1.625rem;
	height: 1.625rem;
	z-index: 1;
}

@media (--mq-from-wide) {
	.age-gate-trigger__pointer {
		right: -0.3125rem;
		width: 2.125rem;
		height: 2.125rem;
	}
}

.age-gate-trigger__pointer > svg {
	display: block;
	width: 100%;
	height: 100%;
}
