:root {
	/* COLORS */
	--color-black: #000000;
	--color-blue-dark: #322aa1;
	--color-blue-light: #6bbfef;
	--color-green: #70a401;
	--color-grey: #b4b4b4;
	--color-grey-light: #e2e2e2;
	--color-orange: #ff7900;
	--color-pink: #faa6c7;
	--color-red: #fe0808;
	--color-white: #ffffff;
	--color-yellow: #ffde24;
	--color-sand: #ffefc3;
	/*  */
	--color-alpha-black-30: rgb(0 0 0 / 0.3);
	--color-alpha-black-35: rgb(0 0 0 / 0.35);
	--color-alpha-black-75: rgb(0 0 0 / 0.75);
	/*  */
	--color-foreground: var(--color-black);
	--color-background: var(--color-white);
	--color-body-background: var(--color-green);
	/*  */
	--color-alpha-foreground-15: rgb(0 0 0 / 0.15);
	--color-alpha-foreground-30: rgb(0 0 0 / 0.3);
	--color-alpha-foreground-75: rgb(0 0 0 / 0.75);
	/*  */
	--color-error: var(--color-red);

	/* SPACES */
	--space-10: 0.625rem;
	--space-12: 0.75rem;
	--space-15: 0.9375rem;
	--space-20: 1.25rem;
	--space-25: 1.5625rem;
	--space-30: 1.875rem;
	--space-35: 2.1875rem;
	--space-40: 2.5rem;
	--space-50: 3.125rem;
	--space-70: 4.375rem;

	/* EASINGS */
	--ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);

	/* MAJOR LAYOUT ELEMENTS */
	--site-header-min-height: auto;
	--site-header-min-height-from-small: 9.375rem;
	--site-header-min-height-from-wide: 12.5rem;
	/*  */
	--site-footer-min-height: 6.875rem;
	--site-footer-min-height-from-small: var(--site-header-min-height-from-small);
	--site-footer-min-height-from-wide: var(--site-header-min-height-from-wide);
	/*  */
	--site-content-overlap: 3.75rem;
	--site-content-overlap-from-small: 8.75rem;
	--site-content-overlap-from-medium: 5rem;
	--site-content-overlap-from-wide: 8.75rem;
	/*  */
	--scroll-to-top-star-size: 3.75rem;
	--scroll-to-top-star-size-from-wide: 4.375rem;

	/* FONTS & TYPOGRAPHY */

	/** Proxima Nova Condensed (via Adobe Fonts aka Typekit)
	 * 400 → regular
	 * 700 → bold
	 * 900 → black
	 */
	--font-sans: proxima-nova-condensed, "Arial Narrow", "Arial", sans-serif;

	/** Gill Sans MT Pro Condensed (via MyFonts pack)
	 * 400 → regular
	 */
	--font-title: "GillSansMTPro-BoldCondensed", "Arial Narrow", "Arial", sans-serif;
	/*  */
	--font-weight-regular: 400;
	--font-weight-bold: 700;
	--font-weight-black: 900;

	/** Gill Sans uses descenders for it's J and Q caps, which makes all
	 * other caps align at the top, and gives everything an unbalanced feel.
	 * To counter this, here are some variables to level out the (mis)alignment.
	 *
	 * These levelers are rounded values calculated by doing font-size * 0.3125.
	 * Some are adjusted up a bit, some down, based on visual checks.
	 */
	--type-a-leveler: 0.5625rem;
	--type-a-leveler-from-wide: 0.75rem;
	/*  */
	--type-b-leveler: 0.4375rem;
	--type-b-leveler-from-wide: 0.5rem;
	/*  */
	--type-c-leveler: 0.3125rem;
	--type-c-leveler-from-wide: 0.4375rem;
	/*  */
	--type-d-leveler: 0.3125rem;
	--type-d-leveler-from-wide: 0.375rem;
	/*  */
	--type-f-leveler: 0.25rem;
	--type-f-leveler-from-wide: 0.3125rem;
	/*  */
	--type-g-leveler: 0.25rem;
	--type-g-leveler-from-wide: 0.3125rem;

	/* Site container */
	--site-container-width: calc(100vw - (var(--space-20) * 2));
	--site-container-width-from-small: 728px;
	--site-container-width-from-wide: 1040px;

	/* Z indices for modals and dialogs */
	--z-index-age-gate-md-fg: 5050;
	--z-index-age-gate-md-bg: 5040;
	/*  */
	--z-index-privacy-d-fg: 4050;
	/*  */
	--z-index-privacy-alert-youtube-md-fg: 3050;
	--z-index-privacy-alert-youtube-md-bg: 3040;
	/*  */
	--z-index-sttupid-md-fg: 2050;
	--z-index-sttupid-md-bg: 2040;
	/*  */
	--z-index-label-creator-md-fg: 2050;
	--z-index-label-creator-md-bg: 2040;
	/*  */
	--z-index-hover-label: 50;

	/* Z indices for site layout */
	--z-index-site-footer: 1;
	--z-index-site-content: 2;
	--z-index-site-header: 3;
	--z-index-stickers: 4;

	/* Playa banner */
	--wave-height: 100px;
	--wave-width: 75px;
}
