.asterisk {
	background-image: url("../images/asterisk.svg");
	background-position: top left;
	background-repeat: no-repeat;
	background-size: contain;
}

h1 .asterisk,
h2 .asterisk,
h3 .asterisk,
h4 .asterisk,
h5 .asterisk,
h6 .asterisk {
	display: inline-block;
	transform: translateY(-0.125rem);
}

.asterisk__label {
	visibility: hidden;
}
