.sticker {
	opacity: 0;
	pointer-events: none;
	position: absolute;
	z-index: var(--z-index-stickers);
}

@media (prefers-reduced-motion: reduce), (max-width: 767px) {
	.sticker {
		display: none;
	}
}

.sticker.sticker--left {
	animation:
		STICKER_OPACITY 96ms linear 512ms forwards,
		STICKER_LEFT 320ms var(--ease-out-quad) 512ms forwards;
	right: calc(50% + 364px + 74px);
	transform: translate3d(50%, -50%, 0) scale(0.5) rotate(-8deg);
}

@media (min-width: 1280px) {
	.sticker.sticker--left {
		right: calc(50% + 520px + 65px);
	}
}

@media (min-width: 1440px) {
	.sticker.sticker--left {
		right: calc(50% + 520px + 100px);
	}
}

.sticker.sticker--right {
	animation:
		STICKER_OPACITY 96ms linear 512ms forwards,
		STICKER_RIGHT 320ms var(--ease-out-quad) 512ms forwards;
	left: calc(50% + 364px + 74px);
	transform: translate3d(-50%, -50%, 0) scale(0.5) rotate(8deg);
}

@media (min-width: 1280px) {
	.sticker.sticker--right {
		left: calc(50% + 520px + 65px);
	}
}

@media (min-width: 1440px) {
	.sticker.sticker--right {
		left: calc(50% + 520px + 100px);
	}
}

@keyframes STICKER_OPACITY {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes STICKER_LEFT {
	0% {
		transform: translate3d(50%, -50%, 0) scale(0.5) rotate(-8deg);
	}

	100% {
		transform: translate3d(50%, -50%, 0);
	}
}

@keyframes STICKER_RIGHT {
	0% {
		transform: translate3d(-50%, -50%, 0) scale(0.5) rotate(8deg);
	}

	100% {
		transform: translate3d(-50%, -50%, 0);
	}
}
