/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.labels {
	overflow: hidden;
}

.labels__inner {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

a.labels__inner {
	color: currentColor;
	text-decoration: none;
}

@media (--mq-up-to-small) {
	.labels__inner {
		margin-right: -37.5%;
		margin-left: -37.5%;
		width: 175%;
	}
}

.labels__figure,
.labels__heading {
	height: calc((100vw - 2.5rem) / 175 * 100 / 280 * 130 + 1.6rem);
}

/* maintain this ratio on mobile and add height for title label */
@media (--mq-from-small) {
	.labels__figure,
	.labels__heading {
		height: 8.75rem;
	}
}

@media (--mq-from-wide) {
	.labels__figure,
	.labels__heading {
		height: 12.5rem;
	}
}

.labels__heading {
	display: flex;
	flex-basis: calc(100% / 175 * 100);
	flex-direction: column;
	order: 1;
	justify-content: center;
	align-items: center;
	text-align: center;
}

@media (--mq-up-to-small) {
	.labels__heading {
		margin-right: calc(100% / 175 * 37.5);
		margin-left: calc(100% / 175 * 37.5);
	}
}

@media (--mq-from-small) {
	.labels__heading {
		flex-basis: 50%;
	}
}

.labels__item {
	flex-basis: 25%;
}

@media (--mq-from-small) {
	.labels__item:nth-of-type(n + 6) {
		order: 2;
	}

	.labels__item:nth-of-type(n + 23) {
		/* 22 items are visible */
		display: none;
	}
}

@media (--mq-up-to-small) {
	.labels__item {
		min-width: 0; /* fix for mobile layout */

		/* only on teaser we're going to move the 4th at the end of the last row */

		/* only on teaser we're going to translate the first row */

		/* row 2, 5, 7, ... */

		/* row 3, 6, 9, ... */
	}

	.labels__item:nth-of-type(n + 21) {
		/* 20 items are visible */
		display: none;
	}

	.labels__item:nth-of-type(n + 5) {
		order: 2;
	}

	.labels--teaser .labels__item:nth-of-type(4) {
		order: 3;
		position: relative;
		transform: translateX(-15%);
		z-index: 2;
	}

	.labels--teaser .labels__item:nth-of-type(1),
	.labels--teaser .labels__item:nth-of-type(2),
	.labels--teaser .labels__item:nth-of-type(3) {
		transform: translateX(10%);
	}

	.labels__item:nth-of-type(12n-4),
	.labels__item:nth-of-type(12n-5),
	.labels__item:nth-of-type(12n-6),
	.labels__item:nth-of-type(12n-7) {
		transform: translateX(15%);
	}

	.labels__item:nth-of-type(12n),
	.labels__item:nth-of-type(12n-1),
	.labels__item:nth-of-type(12n-2),
	.labels__item:nth-of-type(12n-3) {
		transform: translateX(-15%);
	}
}

.labels__title {
	font-family: var(--font-sans);
	font-size: 1.875rem;
	font-weight: var(--font-weight-black);
	line-height: 0.83;
	margin-bottom: var(--space-12);
	max-width: 270px;
}

@media (--mq-from-wide) {
	.labels__title {
		font-size: 2.5rem;
		line-height: 0.75;
		margin-bottom: var(--space-20);
	}
}

a.labels__inner .labels__title span {
	position: relative;
}

a.labels__inner .labels__title span::after {
	background-color: var(--color-green);
	content: "";
	opacity: 0;
	position: absolute;
	top: calc(50% - 0.3125rem);
	left: 0;
	transition: opacity 64ms linear;
	width: 100%;
	height: 0.375rem;
	z-index: 2;
}

@media (--mq-from-small) {
	a.labels__inner .labels__title span::after {
		top: calc(50% - 0.375rem);
		height: 0.5rem;
	}
}

a.labels__inner .labels__heading:focus .labels__title span::after,
a.labels__inner .labels__heading:hover .labels__title span::after {
	opacity: 1;
}

.labels__subtitle {
	margin-top: 0;
	text-transform: none;
}

.labels__figure {
	display: flex;
	flex-direction: column-reverse;
	margin: 0;
	width: 100%;
}

.labels__figcaption {
	background-color: var(--color-black);
	flex-grow: 1;
	font-family: var(--font-title);
	font-size: 0.75rem;
	font-weight: var(--font-weight-regular);
	line-height: 1;
	overflow: hidden;
	padding: 0.5rem 0.5rem 0;
	text-overflow: ellipsis;
	text-transform: uppercase;
	transition: background-color 96ms linear;
	white-space: nowrap;
	width: calc((var(--site-container-width) * 0.5) - (var(--space-12) * 2));
}

/* half of site container - padding */
@media (--mq-from-small) {
	.labels__figcaption {
		padding-top: var(--space-10);
		padding-right: var(--space-12);
		padding-left: var(--space-12);
		width: calc((var(--site-container-width-from-small) * 0.25) - (var(--space-12) * 2)); /* quarter of site container - padding */
	}
}

@media (--mq-from-wide) {
	.labels__figcaption {
		font-size: 1.25rem;
		padding-top: 0.875rem;
		width: calc((var(--site-container-width-from-wide) * 0.25) - (var(--space-12) * 2)); /* quarter of site container - padding */
	}
}

a.labels__inner .labels__figure:hover .labels__figcaption {
	background-color: var(--color-red);
}

.labels__item[swapped-in] .labels__figcaption {
	animation: FLASH_RED 624ms 1 forwards ease-in;
}

@keyframes FLASH_RED {
	0%,
	100% {
		background-color: var(--color-black);
	}

	33% {
		background-color: var(--color-red);
	}
}
