.section {
	padding-top: 2rem;
	padding-bottom: 2rem;
	position: relative;
}

@media (--mq-from-small) {
	.section {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}

@media (--mq-from-medium) {
	.section {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

@media (--mq-from-wide) {
	.section {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}
}

.section.section--small {
	padding-top: var(--space-20);
	padding-bottom: var(--space-20);
}

@media (--mq-from-small) {
	.section.section--small {
		padding-top: var(--space-30);
		padding-bottom: var(--space-30);
	}
}

@media (--mq-from-wide) {
	.section.section--small {
		padding-top: var(--space-50);
		padding-bottom: var(--space-50);
	}
}

.section:not([class*="theme-"]) + .section:not([class*="theme-"]),
.section.theme-black + .section.theme-black,
.section.theme-blue-dark + .section.theme-blue-dark,
.section.theme-blue-light + .section.theme-blue-light,
.section.theme-green + .section.theme-green,
.section.theme-grey + .section.theme-grey,
.section.theme-orange + .section.theme-orange,
.section.theme-pink + .section.theme-pink,
.section.theme-red + .section.theme-red,
.section.theme-white + .section.theme-white,
.section.theme-yellow + .section.theme-yellow {
	padding-top: 0;
}
