@keyframes SCROLL_PROGRESS_ANIMATION {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@supports (animation-timeline: scroll(root block)) {
	#mr-scroll-to-top {
		animation-direction: alternate;
		animation-duration: 3s;
		animation-fill-mode: both;
		animation-name: SCROLL_PROGRESS_ANIMATION;
		animation-timeline: scroll(root block);
	}
}
