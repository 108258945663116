.site-container {
	box-sizing: border-box;
	padding-right: var(--space-20);
	padding-left: var(--space-20);
	width: 100%;
}

@media (--mq-from-small) {
	.site-container {
		padding-right: calc((100% - var(--site-container-width-from-small)) / 2);
		padding-left: calc((100% - var(--site-container-width-from-small)) / 2);
	}
}

@media (--mq-from-wide) {
	.site-container {
		padding-right: calc((100% - var(--site-container-width-from-wide)) / 2);
		padding-left: calc((100% - var(--site-container-width-from-wide)) / 2);
	}
}
