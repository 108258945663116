.theme-black {
	background-color: var(--color-black);
}

.theme-blue-dark {
	background-color: var(--color-blue-dark);
}

.theme-blue-light {
	background-color: var(--color-blue-light);
}

.theme-green {
	background-color: var(--color-green);
}

.theme-grey {
	background-color: var(--color-grey);
}

.theme-grey-light {
	background-color: var(--color-grey-light);
}

.theme-orange {
	background-color: var(--color-orange);
}

.theme-pink {
	background-color: var(--color-pink);
}

.theme-sand {
	background-color: var(--color-sand);
}

.theme-red {
	background-color: var(--color-red);
}

.theme-white {
	background-color: var(--color-white);
}

.theme-yellow {
	background-color: var(--color-yellow);
}

.theme-black,
.theme-blue-dark {
	color: var(--color-white);
}

.theme-blue-light,
.theme-green,
.theme-grey,
.theme-grey-light,
.theme-orange,
.theme-pink,
.theme-red,
.theme-sand,
.theme-white,
.theme-yellow {
	color: var(--color-black);
}

.theme-text-black {
	color: var(--color-black);
}

.theme-text-blue-dark {
	color: var(--color-blue-dark);
}

.theme-text-blue-light {
	color: var(--color-blue-light);
}

.theme-text-red {
	color: var(--color-red);
}

.theme-text-green {
	color: var(--color-green);
}

.theme-text-grey {
	color: var(--color-grey);
}

.theme-text-grey-light {
	color: var(--color-grey-light);
}

.theme-text-orange {
	color: var(--color-orange);
}

.theme-text-pink {
	color: var(--color-pink);
}

.theme-text-sand {
	color: var(--color-sand);
}

.theme-text-white {
	color: var(--color-white);
}

.theme-text-yellow {
	color: var(--color-yellow);
}
