.site-header {
	box-sizing: border-box;
	padding-top: var(--space-20);
	padding-bottom: var(--space-12);
	min-height: var(--site-footer-min-height);
}

@media (min-width: 768px) {
	.site-header {
		display: flex;
		flex-direction: column;
		padding: var(--space-30) var(--space-20);
		justify-content: center;
		align-items: center;
		width: 100%;
		min-height: var(--site-header-min-height-from-small);
	}
}

@media (min-width: 1280px) {
	.site-header {
		min-height: var(--site-footer-min-height-from-wide);
	}
}

.site-header__inner {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	width: 100%;
}

@media (min-width: 768px) {
	.site-header__inner {
		flex-wrap: nowrap;
		align-items: center;
	}
}

/* NAVIGATION */
.site-header__navigation-container {
	flex-basis: auto;
	flex-grow: 0;
}

@media (max-width: 767px) {
	.site-header__navigation-container {
		order: 3;
		width: 100%;
	}
}

.site-header__navigation {
	flex-basis: auto;
}

@media (min-width: 420px) {
	.site-header__navigation {
		display: flex;
		align-items: center;
	}
}

@media (min-width: 768px) {
	.site-header__navigation > *:not(:last-child) {
		margin-right: var(--space-20);
	}
}

@media (min-width: 768px) {
	.site-header__navigation__primary-container,
	.site-header__navigation__scroll-to-top-container,
	.site-header__navigation__spotlight-container {
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 1;
	}
}

@media (min-width: 420px) and (max-width: 767px) {
	.site-header__navigation__primary-container,
	.site-header__navigation__spotlight-container {
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 1;
	}
}

.site-header__navigation__scroll-to-top-container,
.site-header__navigation__scroll-to-top,
.site-header__navigation__scroll-to-top-link {
	width: var(--scroll-to-top-star-size);
	height: var(--scroll-to-top-star-size);
}

@media (min-width: 1280px) {
	.site-header__navigation__scroll-to-top-container,
	.site-header__navigation__scroll-to-top,
	.site-header__navigation__scroll-to-top-link {
		width: var(--scroll-to-top-star-size-from-wide);
		height: var(--scroll-to-top-star-size-from-wide);
	}
}

@media (max-width: 767px) {
	.site-header__navigation__scroll-to-top-container {
		position: fixed;
		bottom: var(--space-10);
		left: var(--space-10);
		z-index: 99999;
	}
}

.site-header__navigation__scroll-to-top {
	display: block;
}

.site-header__navigation__scroll-to-top-link {
	color: currentColor;
	outline: 0;
	text-decoration: none;
}

.site-header__navigation__scroll-to-top-link > svg {
	display: block;
	width: 100%;
	height: 100%;
}

@media (min-width: 420px) and (max-width: 767px) {
	.site-header__navigation__spotlight-container {
		text-align: right;
	}

	.site-header__navigation__primary-container ~ .site-header__navigation__spotlight-container {
		margin-left: var(--space-20);
	}
}

/* LOGO */
.site-header__logo-container {
	display: flex;
	flex-basis: auto;
	flex-grow: 1;
	justify-content: flex-start;
	align-items: center;
}

@media (max-width: 767px) {
	.site-header__logo-container {
		margin-bottom: var(--space-20);
		order: 1;
	}
}

@media (min-width: 768px) {
	.site-header__logo-container {
		justify-content: center;
	}
}

.site-header__logo {
	display: inline-block;
	width: 105px;
	height: 27px;
}

@media (min-width: 768px) {
	.site-header__logo {
		width: 138px;
		height: 35px;
	}
}

@media (min-width: 1280px) {
	.site-header__logo {
		width: 162px;
		height: 41px;
	}
}

.site-header__logo svg {
	display: block;
	width: 100%;
	height: 100%;
}

/* REGION */
.site-header__age-gate-trigger-container {
	flex-basis: auto;
	flex-grow: 0;
}

@media (max-width: 767px) {
	.site-header__age-gate-trigger-container {
		order: 2;
	}
}

/* SOCIAL */
.site-header__social-navigation {
	display: none;
}

@media (min-width: 1024px) {
	.site-header__social-navigation {
		display: block;
		position: absolute;
		top: 75vh;
		left: calc(50% + 364px - 30px); /* 50vw + wrapper/2 - 30px */
		transform: rotate(13deg);
	}
}

@media (min-width: 1280px) {
	.site-header__social-navigation {
		left: calc(50% + 520px - 30px); /* 50vw + wrapper/2 - 30px */
	}
}

.l-styleguide .site-header__social-navigation {
	display: none;
}
/** POSITION VARIATIONS
	 *
	 * The position of the social navigation can be set by the admin.
	 * There are six options:
	 *
	 * - Left High
	 * - Left Mid
	 * - Left Low
	 * - Right High
	 * - Right Mid
	 * - Right Low (default, set above)
	 */
.site-header__social-navigation[data-position="left-high"] {
	top: calc(var(--site-header-min-height-from-small) + 5vh);
	right: calc(50% + 364px - 40px);
	left: auto;
	transform: rotate(-8deg);
}

@media (min-width: 1280px) {
	.site-header__social-navigation[data-position="left-high"] {
		right: calc(50% + 520px - 40px);
	}
}

.site-header__social-navigation[data-position="left-mid"] {
	top: 49vh;
	right: calc(50% + 364px - 30px);
	left: auto;
	transform: rotate(9deg);
}

@media (min-width: 1280px) {
	.site-header__social-navigation[data-position="left-mid"] {
		right: calc(50% + 520px - 30px);
	}
}

.site-header__social-navigation[data-position="left-low"] {
	top: 71vh;
	right: calc(50% + 364px - 15px);
	left: auto;
	transform: rotate(-4deg);
}

@media (min-width: 1280px) {
	.site-header__social-navigation[data-position="left-low"] {
		right: calc(50% + 520px - 15px);
	}
}

.site-header__social-navigation[data-position="right-high"] {
	top: calc(var(--site-header-min-height-from-small) + 6vh);
	left: calc(50% + 364px - 15px);
	transform: rotate(-6deg);
}

@media (min-width: 1280px) {
	.site-header__social-navigation[data-position="right-high"] {
		left: calc(50% + 520px - 15px);
	}
}

.site-header__social-navigation[data-position="right-mid"] {
	top: 52vh;
	left: calc(50% + 364px - 40px);
	transform: rotate(6deg);
}

@media (min-width: 1280px) {
	.site-header__social-navigation[data-position="right-mid"] {
		left: calc(50% + 520px - 40px);
	}
}
