.l-error {
	background-image: url("../images/error-background.svg");
	background-position: top center;
	background-repeat: repeat-y;
	background-size: 2560px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	text-align: center;
	width: 100%;
	min-height: 100vh;
}

@media (min-width: 2560px) {
	.l-error {
		background-size: 100%;
	}
}

.l-error__content {
	margin: auto;
	width: calc(100% - (var(--space-20) * 2));
}

@media (--mq-from-small) {
	.l-error__content {
		width: 600px;
	}
}

@media (--mq-from-wide) {
	.l-error__content {
		width: 800px;
	}
}

.l-error__content__inner {
	box-sizing: border-box;
	margin-right: auto;
	margin-left: auto;
	overflow-x: hidden;
	overflow-y: auto;
	padding: var(--space-50) var(--space-20);
	max-height: 80vh;
}

@media (--mq-from-small) {
	.l-error__content__inner {
		padding-right: calc((100% - 240px) / 2);
		padding-left: calc((100% - 240px) / 2);
	}
}

@media (--mq-from-wide) {
	.l-error__content__inner {
		padding-right: calc((100% - 320px) / 2);
		padding-left: calc((100% - 320px) / 2);
	}
}

.l-error__logo {
	color: currentColor;
	display: inline-block;
	margin-bottom: var(--space-50);
	text-decoration: none;
	width: 105px;
	height: 27px;
}

@media (--mq-from-wide) {
	.l-error__logo {
		margin-bottom: var(--space-70);
		width: 162px;
		height: 41px;
	}
}

.l-error__logo svg {
	display: block;
	width: 100%;
	height: 100%;
}

.l-error__title {
	margin-top: 0;
	margin-bottom: var(--space-40) !important;
}

@media (--mq-from-wide) {
	.l-error__title {
		margin-bottom: var(--space-50) !important;
	}
}
