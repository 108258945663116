.u-hidden {
	display: none !important;
}

.u-visually-hidden {
	border: none !important;
	clip: rect(0 0 0 0) !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	white-space: nowrap !important;
	width: 1px !important;
	height: 1px !important;
}

.u-forced-aspect-ratio {
	overflow: hidden;
	position: relative;
	height: 0;
}

.u-forced-aspect-ratio[data-ratio="1:1"] {
	padding-top: 100%;
}

.u-forced-aspect-ratio[data-ratio="2:1"] {
	padding-top: 50%;
}

.u-forced-aspect-ratio[data-ratio="4:3"] {
	padding-top: 75%;
}

.u-forced-aspect-ratio[data-ratio="3:2"] {
	padding-top: calc(100% / 3 * 2);
}

.u-forced-aspect-ratio[data-ratio="16:9"] {
	padding-top: 56.25%;
}

.u-forced-aspect-ratio[data-ratio="21:9"] {
	padding-top: calc(100% / 21 * 9);
}

.u-forced-aspect-ratio__inside {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.u-default-block-padding {
	padding: var(--space-20);
}

@media (--mq-from-medium) {
	.u-default-block-padding {
		padding: var(--space-40);
	}
}

@media (--mq-from-large) {
	.u-default-block-padding {
		padding: var(--space-50);
	}
}

.u-box-shadow-modal-dialog {
	box-shadow: 0.625rem 0.625rem 0 0 var(--color-blue-light);
}

.u-box-shadow-dialog {
	box-shadow: 0.4375rem 0.4375rem 0 0 rgb(0 0 0 / 0.21);
}

.u-box-shadow-tooltip {
	box-shadow: 0.3125rem 0.3125rem 0 0 rgb(0 0 0 / 0.4);
}

.u-faded {
	color: currentColor;
	opacity: 0.4;
}

.u-styleguide-full-viewport {
	height: 100vh;
}

.u-styleguide-relative {
	position: relative;
}

.u-text-align-center {
	text-align: center;
}
